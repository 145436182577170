import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

type CognitoToken = {
  token: string;
  expiration: number;
};

interface AuthState {
  cognitoToken: CognitoToken | null;
  hybridToken: string;
  identity: string;
  proposalToken: string;
}

const initialState: AuthState = {
  cognitoToken: null,
  hybridToken: "",
  identity: "",
  proposalToken: "",
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setCognitoToken: (state, action: PayloadAction<CognitoToken>) => {
      state.cognitoToken = action.payload;
    },
    setHybridToken: (state, action: PayloadAction<string>) => {
      state.hybridToken = action.payload;
    },
    setIdentity: (state, action: PayloadAction<string>) => {
      state.identity = action.payload;
    },
    setProposalToken: (state, action: PayloadAction<string>) => {
      state.proposalToken = action.payload;
    },
  },
});

const authReducer = authSlice.reducer;

const {
  setCognitoToken: authSetCognitoToken,
  setHybridToken: authSetHybridToken,
  setIdentity: authSetIdentity,
  setProposalToken: authSetProposalToken,
} = authSlice.actions;

export type { CognitoToken };
export {
  authReducer,
  authSetCognitoToken,
  authSetHybridToken,
  authSetIdentity,
  authSetProposalToken,
};
