import React from "react";
import { useMediaQuery } from "react-responsive";
import {
  buildLeftcolMiddleRightcolRow,
  buildLeftRightColumnRow,
} from "../atoms/layoutAtoms";
import { useCapContext } from "../../providers/CapContext";
import { Asset } from "../../../../amplify/backend/function/OfferExpContacts/ts/public/types";
import { formatNumber } from "../../../utils/formatHelpers";
import { RowProps } from "../../capTypes";

const productTuple = (
  item: string,
  originalAsset?: Asset,
  activeProposalAsset?: Asset
) => [
  item,
  `${originalAsset?.count ? formatNumber(originalAsset?.count ?? 0, 0) : "-"}`,
  `${originalAsset?.model ?? ""}`,
  `${
    activeProposalAsset?.count
      ? formatNumber(activeProposalAsset?.count ?? 0, 0)
      : "-"
  }`,
  `${activeProposalAsset?.model ?? ""}`,
];

const ProductRows = ({ viewType, isLoading = true }: RowProps) => {
  const { prospect, originalProposal } = useCapContext();
  const isMobile = useMediaQuery({ maxWidth: 750 });

  const activeProposalPanels = prospect?.activeProposal?.assets.find((y: any) =>
    y.type.toLowerCase().includes("panel")
  );
  const originalProposalPanels = originalProposal?.assetInfo.find((y: any) =>
    y.type.toLowerCase().includes("panel")
  );

  const hasSolarPanels = !!activeProposalPanels || !!originalProposalPanels;

  const activeProposalBatteries = prospect?.activeProposal?.assets.filter(
    (asset: Asset) => {
      return (
        asset?.type === "Battery" ||
        asset?.type?.toLowerCase().includes("battery")
      );
    }
  )[0];

  const originalProposalBatteries = originalProposal?.assetInfo.filter(
    (asset) => {
      return (
        asset.type === "Battery" ||
        asset?.type?.toLowerCase().includes("battery")
      );
    }
  )[0];

  const hasBattery = !!activeProposalBatteries || !!originalProposalBatteries;

  // Expansion batteries
  const activeProposalExpansionBatteries: Asset | undefined =
    prospect?.activeProposal?.assets.filter((asset: Asset) => {
      return asset?.model?.toLowerCase().includes("expansion");
    })[0];

  const originalProposalExpansionBatteries = originalProposal?.assetInfo.filter(
    (asset) => {
      return (
        asset?.model?.toLowerCase().includes("expansion") ||
        asset?.sku?.toLowerCase().includes("expansion")
      );
    }
  )[0];

  const hasExpansionBatteries =
    !!activeProposalExpansionBatteries || !!originalProposalExpansionBatteries;

  const activeProposalInverters = prospect?.activeProposal?.assets.filter(
    (asset: Asset) => {
      return asset?.type?.toLowerCase().includes("inverter");
    }
  )[0];

  const originalProposalInverters = originalProposal?.assetInfo.filter(
    (asset: Asset) => {
      return asset?.type?.toLowerCase().includes("inverter");
    }
  )[0];

  const hasInverter = !!activeProposalInverters || !!originalProposalInverters;

  const activeProposalSpan = prospect?.activeProposal?.assets.filter(
    (asset: any) => {
      return asset.manufacturer.toLowerCase().includes("span");
    }
  )[0];

  const originalProposalSpan = originalProposal?.assetInfo.filter(
    (asset: any) => {
      return asset.manufacturer.toLowerCase().includes("span");
    }
  )[0];

  const hasSmartPanels = !!activeProposalSpan || !!originalProposalSpan;

  const productTypes = [
    hasSolarPanels,
    hasBattery,
    hasExpansionBatteries,
    hasInverter,
    hasSmartPanels,
  ];
  let productCount = 0;

  productTypes.forEach((prodType) => {
    if (prodType) {
      productCount++;
    }
  });

  const rows = [];
  if (hasSolarPanels) {
    let underline = productCount > 1;
    const solarTuple = productTuple(
      "Panels",
      originalProposalPanels,
      activeProposalPanels
    );
    if (viewType === "Single") {
      rows.push(
        buildLeftRightColumnRow(solarTuple, underline, isLoading, isMobile)
      );
    } else {
      rows.push(
        buildLeftcolMiddleRightcolRow(
          solarTuple,
          underline,
          isLoading,
          isMobile
        )
      );
    }
  }
  if (hasBattery) {
    let underline = hasInverter || hasSmartPanels || hasExpansionBatteries;
    const batteryTuple = productTuple(
      "Batteries",
      originalProposalBatteries,
      activeProposalBatteries
    );
    if (viewType === "Single") {
      rows.push(
        buildLeftRightColumnRow(batteryTuple, underline, isLoading, isMobile)
      );
    } else {
      rows.push(
        buildLeftcolMiddleRightcolRow(
          batteryTuple,
          underline,
          isLoading,
          isMobile
        )
      );
    }
  }
  if (hasExpansionBatteries) {
    let underline = hasInverter || hasSmartPanels;
    const expansionTuple = productTuple(
      "Expansion batteries",
      originalProposalExpansionBatteries,
      activeProposalExpansionBatteries
    );
    if (viewType === "Single") {
      rows.push(
        buildLeftRightColumnRow(expansionTuple, underline, isLoading, isMobile)
      );
    } else {
      rows.push(
        buildLeftcolMiddleRightcolRow(
          expansionTuple,
          underline,
          isLoading,
          isMobile
        )
      );
    }
  }
  if (hasInverter) {
    let underline = hasSmartPanels;
    const inverterTuple = productTuple(
      "Inverters",
      originalProposalInverters,
      activeProposalInverters
    );

    if (viewType === "Single") {
      rows.push(
        buildLeftRightColumnRow(inverterTuple, underline, isLoading, isMobile)
      );
    } else {
      rows.push(
        buildLeftcolMiddleRightcolRow(
          inverterTuple,
          underline,
          isLoading,
          isMobile
        )
      );
    }
  }
  if (hasSmartPanels) {
    const spanTuple = productTuple(
      "Smart Panels",
      originalProposalSpan,
      activeProposalSpan
    );
    if (viewType === "Single") {
      rows.push(buildLeftRightColumnRow(spanTuple, false, isLoading, isMobile));
    } else {
      rows.push(
        buildLeftcolMiddleRightcolRow(spanTuple, false, isLoading, isMobile)
      );
    }
  }

  return <>{rows.map((row) => row)}</>;
};

export { ProductRows, productTuple };
