import React, { useState } from "react";
import {
  Button,
  LoadingIndicator,
  Modal,
  Skeleton,
  tokens,
  Typography,
} from "@sunrun/experience-ui-components";
import { useParams } from "react-router-dom";
import styled from "@emotion/styled/macro";
import { useMediaQuery } from "react-responsive";
import { rollbar } from "../../../providers/rollbar";
import { useAppSelector } from "../../../store";
import { postProspectProposalEmail } from "../../api/capApi";
import { HomeReadinessStepView } from "../organisms/HomeReadinessStepView";
import { ReadSignStepView } from "../organisms/ReadSignStepView";
import { DesignStepView } from "../organisms/DesignStepView";
import { useCapContext } from "../../providers/CapContext";
import heroImg from "../../assets/hero-image.svg";
import triangleOverlay from "../../assets/triangle_overlay.svg";
import { CapNav } from "../molecules/CapNav";
import { NextSteps } from "../organisms/NextStepsStepView";
import sunrunMobile from "../../assets/sunrunMobile.svg";
import AppleAppStore from "../../assets/AppleAppStore.svg";
import DownloadAppQR from "../../assets/DownloadAppQR.svg";
import GooglePlayStore from "../../assets/GooglePlayStore.svg";
import halfIphoneImg from "../../assets/iPhone.png";
import fullIphoneImg from "../../assets/iphone-tablet-view.svg";
import halfIPhoneAutopayImg from "../../assets/iphone-autopay-fullscreen-view.svg";
import fullIPhoneAutopayImg from "../../assets/iphone-autopay-tablet-view.svg";
import { EquipmentLocationStepView } from "../organisms/EquipmentLocationStepView";
import { StepName } from "../../capTypes";
import { STEPS } from "../../constants/capConstants";

const CapPage: React.FC = () => {
  const { prospectId = "" } = useParams();
  const authKey = useAppSelector((state) => state?.auth?.hybridToken);
  const [confirmEmail, setConfirmEmail] = useState(false);
  const [isEmailSending, setIsEmailSending] = useState(false);
  const [currentStep, changeStep] = useState<StepName>(STEPS.SYSTEM_DESIGN);
  const isMobile = useMediaQuery({ maxWidth: 750 });
  const isTablet = useMediaQuery({ maxWidth: 1024 });

  const {
    activeProposal,
    activeProposalLoading,
    contacts,
    isContactsLoading,
    isSowLoading,
    prospect,
    prospectLoading,
    originalProposalDesignImagesLoading,
    isCapLoading,
  } = useCapContext();

  const showAutopay =
    activeProposal?.dealType === "FlexMonthly PPA" ||
    activeProposal?.dealType === "FlexMonthly" ||
    activeProposal?.dealType === "monthly";

  const sendProspectProposal = async () => {
    setIsEmailSending(true);
    try {
      await postProspectProposalEmail(authKey, prospectId, "notes");
    } catch (e: any) {
      rollbar.error(e);
    } finally {
      setConfirmEmail(false);
      setIsEmailSending(false);
    }
  };

  type CapPresentationProps = {
    stepName: StepName;
  };

  const CapPresentationContent = ({ stepName }: CapPresentationProps) => {
    switch (stepName) {
      case STEPS.SYSTEM_DESIGN:
        return <DesignStepView />;
      case STEPS.HOME_READINESS:
        return <HomeReadinessStepView />;
      case STEPS.READ_SIGN:
        return <ReadSignStepView />;
      case STEPS.NEXT_STEPS:
        return <NextSteps />;
      case STEPS.EQUIPMENT_LOCATION:
        return <EquipmentLocationStepView />;
      default:
        return <div>Unknown Step Name</div>;
    }
  };

  const primaryContact = contacts?.filter((c) => c.primary)[0];

  const isLoading =
    !contacts ||
    isContactsLoading ||
    activeProposalLoading ||
    isSowLoading ||
    prospectLoading ||
    originalProposalDesignImagesLoading ||
    isCapLoading;

  const CapPageHeaderContainer = () => {
    return isLoading ? (
      <LoadingCapPageHeader />
    ) : (
      <CapPageHeader isMobile={isMobile} isTablet={isTablet}>
        {!isMobile && <TriangleOverlay src={triangleOverlay} />}
        <HeaderContentContainer isMobile={isMobile} isTablet={isTablet}>
          <Typography
            style={{ zIndex: 2, position: "relative" }}
            color={tokens.TINTS_OFF_WHITE_90}
            size={isTablet ? 20 : 32}
            fontWeight={"regular"}
            lineHeight={isTablet ? 26 : 38}
          >
            Final design approval
          </Typography>
          <Typography
            style={{ zIndex: 2, position: "relative" }}
            color={tokens.TINTS_OFF_WHITE_90}
            size={isTablet ? 40 : 72}
            lineHeight={isTablet ? 48 : 80}
            fontWeight={"regular"}
          >
            {primaryContact?.customerFirstName}'s solar project
          </Typography>
        </HeaderContentContainer>
      </CapPageHeader>
    );
  };

  const scrollToTopOfReadSignDocsPage = () => {
    changeStep(STEPS.READ_SIGN);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const getIphoneImg = () => {
    if (currentStep === STEPS.NEXT_STEPS) {
      return isTablet && !isMobile ? fullIphoneImg : halfIphoneImg;
    } else {
      return isTablet && !isMobile
        ? fullIPhoneAutopayImg
        : halfIPhoneAutopayImg;
    }
  };

  return (
    <>
      <CapPageHeaderContainer />
      <CapPageContainer isTablet={isTablet} isMobile={isMobile}>
        <CapNav
          isLoading={isLoading}
          changeStep={(step) => changeStep(step)}
          currentStep={currentStep}
        />
        <ContentContainer>
          <CapPresentationContent stepName={currentStep} />
        </ContentContainer>
      </CapPageContainer>
      {(currentStep === STEPS.NEXT_STEPS ||
        (currentStep === STEPS.READ_SIGN && showAutopay)) && (
        <MobileAppContent isTablet={isTablet} isMobile={isMobile}>
          <DownloadInfo isTablet={isTablet} isMobile={isMobile}>
            <TopRow>
              <img src={sunrunMobile} alt={"sunrun mobile app"} />
              <Typography
                size={isMobile ? 24 : isTablet ? 20 : 32}
                color={tokens.WHITE}
                fontWeight="medium"
                lineHeight={isTablet ? 24 : 44}
              >
                Install the Sunrun mobile app to track your progress
              </Typography>
            </TopRow>
            <Typography
              color={tokens.WHITE}
              size={isMobile ? 18 : isTablet ? 16 : 22}
            >
              Newly designed and easy-to-use, our app is the single access point
              for you to monitor your system, track energy production, manage
              your account, and get support.
            </Typography>
            <Typography
              size={20}
              fontWeight={"medium"}
              color={tokens.WHITE}
              style={{ textAlign: "center" }}
            >
              Download it today.
            </Typography>
            <ImgRow isTablet={isTablet}>
              {isTablet && (
                <StyledImg
                  isTablet={isTablet}
                  src={DownloadAppQR}
                  alt={"QR code to download Sunrun app"}
                />
              )}
              <MobileStoreLink
                target="_blank"
                rel="noreferrer noopener"
                href="https://apps.apple.com/us/app/sunrun/id6443806453"
                aria-label="Apple App store"
              >
                <img src={AppleAppStore} alt={"Apple App Store logo"} />
              </MobileStoreLink>
              {!isTablet && (
                <StyledImg
                  isTablet={isTablet}
                  src={DownloadAppQR}
                  alt={"QR code to download Sunrun app"}
                />
              )}
              <MobileStoreLink
                target="_blank"
                rel="noreferrer noopener"
                href="https://play.google.com/store/apps/details?id=com.sunrun.customerapp"
                aria-label="Google Play store"
              >
                <img src={GooglePlayStore} alt={"Google Play store logo"} />
              </MobileStoreLink>
            </ImgRow>
          </DownloadInfo>
          <PhoneContainer isTablet={isTablet} isMobile={isMobile}>
            <img
              src={getIphoneImg()}
              alt={
                currentStep === STEPS.NEXT_STEPS
                  ? "iPhone with Your Clean Energy Path checklist"
                  : "iPhone with autopay enroll form"
              }
            />
          </PhoneContainer>
        </MobileAppContent>
      )}
      {isLoading ? (
        <FooterSkeleton />
      ) : (
        <Footer isTablet={isTablet}>
          <FooterText isTablet={isTablet} isMobile={isMobile}>
            <Typography
              size={isMobile ? 24 : 32}
              color={tokens.WHITE}
              fontWeight={isTablet ? "medium" : "regular"}
            >
              Take your next step towards solar
            </Typography>
          </FooterText>
          <FooterButtons isTablet={isTablet}>
            <EmailButton
              isTablet={isTablet}
              onClick={() => setConfirmEmail(true)}
            >
              Email CAP to customer
            </EmailButton>
            {currentStep !== STEPS.READ_SIGN && (
              <AgreementsButton
                isTablet={isTablet}
                onClick={() => scrollToTopOfReadSignDocsPage()}
              >
                Read & Sign Docs
              </AgreementsButton>
            )}
          </FooterButtons>
        </Footer>
      )}

      {confirmEmail && (
        <ConfirmEmailModal
          primaryButton={{
            text: "Send Email",
            disabled: isEmailSending,
            onClick: sendProspectProposal,
          }}
          secondaryButton={{
            text: "Cancel",
            disabled: isEmailSending,
            onClick: () => setConfirmEmail(false),
          }}
          onClose={() => setConfirmEmail(false)}
        >
          {!isEmailSending ? (
            <Typography>
              Please confirm, sending email to: {prospect?.customerEmail}
            </Typography>
          ) : (
            <LoadingIndicator color="black" message="Emailing Cap proposal" />
          )}
        </ConfirmEmailModal>
      )}
    </>
  );
};

const ConfirmEmailModal = styled(Modal)``;
const CapPageContainer = styled.section<{
  isTablet: boolean;
  isMobile: boolean;
}>`
  display: flex;
  flex-direction: column;
  padding: ${({ isMobile, isTablet }) =>
    isMobile ? "24px 16px" : isTablet ? "16px 24px" : "40px 64px 64px"};
  background: linear-gradient(
    180deg,
    ${tokens.BACKGROUND_LIGHT} 0,
    ${tokens.CHARGEBLUE_50} 60%,
    ${tokens.BACKGROUND_LIGHT} 90%
  );
  min-height: 550px;
  align-items: center;
`;

const CapPageHeader = styled.header<{ isMobile: boolean; isTablet: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: end;
  background-image: url(${heroImg});
  background-size: cover;
  position: relative;
  height: ${({ isMobile, isTablet }) =>
    isMobile ? "178px" : isTablet ? "212px" : "324px"};
`;

const HeaderContentContainer = styled.div<{
  isMobile: boolean;
  isTablet: boolean;
}>`
  padding: ${({ isMobile, isTablet }) =>
    isMobile ? "16px" : isTablet ? "24px" : "40px 64px"};
  width: ${({ isMobile }) => (isMobile ? "60%" : "fit-content")};
`;

const LoadingCapPageHeader = styled.header`
  height: 324px;
  position: relative;
  background-color: #d4d5da;
`;

const TriangleOverlay = styled.img`
  position: absolute;
  height: 90%;
  z-index: 1;
  bottom: 0;
  right: 0;

  @media screen and (max-width: 768px) {
    height: 50%;
  }
`;

const FooterSkeleton = styled(Skeleton)`
  width: 1133px;
  height: 156px;
`;

const FooterText = styled.div<{ isTablet: boolean; isMobile: boolean }>`
  display: flex;
  justify-content: center;

  > p {
    text-align: ${({ isTablet }) => (isTablet ? "center" : "inherit")};
    width: ${({ isTablet, isMobile }) =>
      isMobile ? "210px" : isTablet ? "100%" : "484px"};
  }
`;

const MobileStoreLink = styled.a`
  display: flex;
  align-items: center;
`;

const FooterButtons = styled.div<{ isTablet: boolean }>`
  width: 100%;
  display: ${({ isTablet }) => (isTablet ? "unset" : "flex")};
  justify-content: ${({ isTablet }) => (isTablet ? "unset" : "flex-end")};
`;

const Footer = styled.div<{ isTablet: boolean }>`
  background: ${tokens.BRAND_HERO_BLUE};
  width: 100%;
  display: flex;
  align-items: center;
  gap: 32px;
  box-sizing: border-box;
  width: 100%;
  padding: ${({ isTablet }) => (isTablet ? "24px 16px" : "50px")};
  flex-direction: ${({ isTablet }) => (isTablet ? "column" : "unset")};

  @media (min-width: 1700px) {
    min-width: 1220px;
  }
`;

const ContentContainer = styled.section`
  max-width: 1200px;
  width: 100%;

  @media (min-width: 1700px) {
    min-width: 1220px;
  }
`;

const EmailButton = styled(Button)<{ isTablet: boolean }>`
  border: 1px solid white;
  height: 62px;
  font-weight: 500;
  margin: ${({ isTablet }) => (isTablet ? "0 0 16px 0" : "0 40px 0 0")};
  width: ${({ isTablet }) => (isTablet ? "100%" : "210px")};
`;

const AgreementsButton = styled(Button)<{ isTablet: boolean }>`
  background: white;
  color: ${tokens.BRAND_HERO_BLUE};
  height: 62px;
  font-weight: 500;
  width: ${({ isTablet }) => (isTablet ? "100%" : "182px")};
`;

const MobileAppContent = styled.section<{
  isTablet: boolean;
  isMobile: boolean;
}>`
  background: linear-gradient(180deg, #1f2647 0%, black 100%);
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? "column" : "80px 80px 0")};
  padding: ${({ isMobile, isTablet }) =>
    isMobile ? "24px 24px 0" : isTablet ? "24px" : "80px 80px 0"};
  gap: ${({ isMobile, isTablet }) =>
    isMobile ? "32px" : isTablet ? "70px" : "100px"};
`;

const DownloadInfo = styled.div<{ isTablet: boolean; isMobile: boolean }>`
  width: ${({ isMobile }) => (isMobile ? "unset" : "55%")};
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-right: ${({ isMobile, isTablet }) =>
    !isMobile && isTablet ? "60px" : "0"};
  padding-bottom: ${({ isMobile, isTablet }) =>
    !isMobile && isTablet ? "60px" : "0"};
  box-sizing: ${({ isTablet }) => (isTablet ? "border-box" : "unset")};

  & > img {
    height: 64px;
    width: 64px;
  }
`;

const TopRow = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  flex-direction: row;
`;

const ImgRow = styled.div<{ isTablet: boolean }>`
  display: flex;
  gap: ${({ isTablet }) => (isTablet ? "16px" : "13%")};
  flex-direction: ${({ isTablet }) => (isTablet ? "column" : "row")};
  align-items: ${({ isTablet }) => (isTablet ? "center" : "unset")};
  justify-content: space-between;
`;

const StyledImg = styled.img<{ isTablet: boolean }>`
  width: ${({ isTablet }) => (isTablet ? "90px" : "110px")};
`;

const PhoneContainer = styled.div<{ isTablet: boolean; isMobile: boolean }>`
  display: flex;
  justify-content: center;

  & > img {
    max-width: ${({ isMobile, isTablet }) =>
      !isMobile && isTablet ? "none" : "310px"};
    height: auto;
    object-fit: cover;
  }
`;

export { CapPage };
