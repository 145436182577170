import { AmplifyPost } from "@sunrun/sales-experience-shared/amplify-api-wrapper";
import {
  GetUploadURLResponse,
  UpdateDesignResponse,
} from "../../amplify/backend/function/OfferExpSiteModel/ts/public/types";
import { logger } from "providers/logger";

const siteModelUpdateDesign = async (
  jwt: string,
  prospectId: string,
  notesToDesigner: string,
  priority: string,
  queue: string,
  status: string
): Promise<UpdateDesignResponse> => {
  return await AmplifyPost<UpdateDesignResponse>(
    "Update Design",
    "OfferExpApi",
    `/prospect/${prospectId}/designRequest/update`,
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
      body: {
        notesToDesigner,
        priority,
        queue,
        status,
      },
    },
    logger
  );
};

const siteModelUploadImage = async (
  jwt: string,
  prospectId: string,
  image: Blob,
  contentType: string
): Promise<GetUploadURLResponse> => {
  const formData = new FormData();
  formData.append("documentBlob", image);
  formData.append("contentType", contentType);
  return await AmplifyPost<GetUploadURLResponse>(
    "Upload Site Model",
    "OfferExpApi",
    `/prospect/${prospectId}/designRequest/image`,
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
        "Content-Type": `multipart/form-data`,
      },
      body: formData,
    },
    logger
  );
};

const siteModelSendEmail = async (
  jwt: string,
  prospectId: string,
  designRequestId: string,
  imageKey?: string
): Promise<""> => {
  return await AmplifyPost(
    "Send Site Model Email",
    "OfferExpApi",
    `/prospect/${prospectId}/designRequest/sendEmail`,
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
      body: {
        designRequestId,
        imageKey,
      },
    },
    logger
  );
};
export { siteModelUpdateDesign, siteModelUploadImage, siteModelSendEmail };
