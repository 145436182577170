import styled from "@emotion/styled/macro";
import { useMemo, useState } from "react";
import { Pill, tokens } from "@sunrun/experience-ui-components";
import sortTriangle from "../assets/images/sort-triangle.svg";
import type { ChangeOrderType } from "../../../amplify/backend/function/offerexpstoreFrontApi/ts/public/constants";
import yellowStar from "../assets/images/yellow-star.svg";
import {
  CHANGE_ORDER_TITLE,
  EV_CHARGER_TITLE,
  type createColumn,
} from "providers/pricing/fields/Columns";
import { PricePoint } from "providers/pricing/PricePoints";
import { columns } from "providers/pricing/fields/Columns";

type Column = (typeof columns)[0];

type props = {
  pricePoints: PricePoint[];
  columns: Column[];
  pricePointSelected: PricePoint["id"];
  onPricePointSelected: (pricePoint: PricePoint["id"]) => any;
  recommendedPricePointId: PricePoint["id"];
  repChangeOrder?: boolean;
};

type Row = {
  value: any;
  displayValue: ReturnType<ReturnType<typeof createColumn>["displayFilter"]>;
  pricePointId: string;
  pricePointStep: number;
}[];

const generateRows = (pricePoints: PricePoint[], columns: Column[]): Row[] => {
  return pricePoints.map((pricePoint) =>
    columns.map((column) => ({
      value: column.value(pricePoint),
      displayValue: column.displayFilter(column.value(pricePoint), pricePoint),
      pricePointId: pricePoint.id,
      pricePointStep: pricePoint.displayDetails?.pricePointStep,
    }))
  );
};

const changeOrderTypes: ChangeOrderType[] = [
  "NO_CHANGE_ORDER",
  "CUSTOMER_CHANGE_ORDER",
  "FULL_PROPOSAL",
];

const getCorrectPill = (
  changeOrderType: ChangeOrderType,
  repRepricing?: boolean
) => {
  const PILL_MAPPING = {
    NO_CHANGE_ORDER: {
      type: "success",
      text: repRepricing ? "No Change" : "Green",
    },
    CUSTOMER_CHANGE_ORDER: {
      type: "warning",
      text: repRepricing ? "Addendum" : "Yellow",
    },
    FULL_PROPOSAL: { type: "error", text: repRepricing ? "New Agrmt." : "Red" },
  } as const;

  return PILL_MAPPING[changeOrderType];
};

const isChangeOrderType = (
  value: ChangeOrderType
): value is ChangeOrderType => {
  return changeOrderTypes.includes(value);
};

const sortByChangeOrderType = (
  aval: ChangeOrderType,
  bval: ChangeOrderType,
  arr: ChangeOrderType[]
): number => {
  return arr.indexOf(bval) - arr.indexOf(aval);
};

const sortRows = (rowA: Row, rowB: Row, sortByIndex: number) => {
  const rowAValue = rowA[sortByIndex]?.value || 0;
  const rowBValue = rowB[sortByIndex]?.value || 0;
  if (isChangeOrderType(rowAValue)) {
    return sortByChangeOrderType(rowAValue, rowBValue, changeOrderTypes);
  }

  if (rowAValue < rowBValue) return -1;
  if (rowAValue > rowBValue) return 1;
  return 0;
};

const PricingColumns = ({
  pricePoints,
  columns = [],
  pricePointSelected,
  onPricePointSelected,
  recommendedPricePointId,
  repChangeOrder,
}: props) => {
  const [sortByIndex, setSortByIndex] = useState(
    columns[0]?.title === CHANGE_ORDER_TITLE ? 0 : 1
  );
  const [isSortByAsc, setIsSortAsc] = useState(false);

  const tableRows = useMemo(() => {
    const rows = generateRows(pricePoints, columns);
    const sortedRows = rows.sort((rowA, rowB) => {
      if (recommendedPricePointId) {
        if (rowA[0].pricePointId === recommendedPricePointId) {
          return isSortByAsc ? -1 : 1;
        } else if (rowB[0].pricePointId === recommendedPricePointId) {
          return isSortByAsc ? 1 : -1;
        }
      }
      return sortRows(rowA, rowB, sortByIndex);
    });

    if (!isSortByAsc) {
      return sortedRows.reverse();
    }

    return sortedRows;
  }, [pricePoints, columns, recommendedPricePointId, isSortByAsc, sortByIndex]);

  const sortBy = (index: number) => {
    if (sortByIndex === index) {
      setIsSortAsc(!isSortByAsc);
    } else {
      setSortByIndex(index);
      setIsSortAsc(true);
    }
  };

  return (
    <Container>
      <ColumnRow>
        <ColumnCell className="leftPadding">Sort By</ColumnCell>
        {columns.map((column, index) => {
          return (
            <ColumnCell
              className={sortByIndex === index ? "sortedBy" : ""}
              onClick={() => sortBy(index)}
              key={"Col" + index}
            >
              <ColumnCellText
                className={
                  (sortByIndex === index ? "sortedBy" : "") +
                  " " +
                  (isSortByAsc ? "asc" : "")
                }
              >
                {column.title}
              </ColumnCellText>
              {column.subTitle && (
                <ColumnCellSubText>{column.subTitle}</ColumnCellSubText>
              )}
            </ColumnCell>
          );
        })}
      </ColumnRow>
      <Rows>
        {tableRows?.map((tableRow, index) => {
          return (
            <DataRow
              key={"Row" + index}
              onClick={() => onPricePointSelected(tableRow[0].pricePointId)}
              className={
                (pricePointSelected ===
                (tableRows[index - 1] || [])[0]?.pricePointId
                  ? "after-selected"
                  : "") +
                (pricePointSelected === tableRow[0].pricePointId
                  ? " selected"
                  : "") +
                (recommendedPricePointId === tableRow[0].pricePointId
                  ? " recommended"
                  : "")
              }
            >
              <DataCell className="radio">
                <DataCellColumnText>Selected</DataCellColumnText>
                <DataCellRadio
                  onSelect={() =>
                    onPricePointSelected(tableRow[0].pricePointId)
                  }
                  title={tableRow[0].pricePointId}
                  type={"radio"}
                  checked={pricePointSelected === tableRow[0].pricePointId}
                  onChange={() => {}}
                  data-testid={"standard-radio"}
                />
              </DataCell>
              {tableRow.map((rowCell, cellIndex) => {
                if (columns[cellIndex]?.title === CHANGE_ORDER_TITLE) {
                  return (
                    <DataCell
                      key={"cell" + index + cellIndex}
                      data-testid="change-order-pill"
                    >
                      <IconContainer>
                        <div>
                          <Pill
                            text={
                              getCorrectPill(
                                rowCell.displayValue as ChangeOrderType,
                                repChangeOrder
                              )?.text ?? ""
                            }
                            type={
                              getCorrectPill(
                                rowCell.displayValue as ChangeOrderType,
                                repChangeOrder
                              )?.type ?? ""
                            }
                          />
                        </div>
                        {rowCell.pricePointId === recommendedPricePointId && (
                          <RecommendedIconArea>
                            <RecommendedIcon
                              src={yellowStar}
                              alt="Recommended"
                            ></RecommendedIcon>
                          </RecommendedIconArea>
                        )}
                      </IconContainer>
                    </DataCell>
                  );
                }
                return (
                  <DataCell
                    key={"cell" + index + cellIndex}
                    className={
                      columns[cellIndex]?.title === EV_CHARGER_TITLE
                        ? " left-bar"
                        : ""
                    }
                  >
                    <DataCellColumnText>
                      {columns[cellIndex]?.title}
                    </DataCellColumnText>

                    <DataCellText className={`cell${cellIndex}`}>
                      {Array.isArray(rowCell.displayValue) ? (
                        <BaseRateHighlight>
                          <div>{rowCell.displayValue[0]}</div>
                          <p>{rowCell.displayValue[1]}</p>
                        </BaseRateHighlight>
                      ) : (
                        rowCell.displayValue || "N/A"
                      )}
                    </DataCellText>
                  </DataCell>
                );
              })}
            </DataRow>
          );
        })}
      </Rows>
    </Container>
  );
};

const minDesktopWidth = 768;

const Container = styled.div`
  font-style: normal;
  font-weight: 400;
  overflow: scroll;
`;
const ColumnRow = styled.div`
  display: none;
  @media (min-width: ${minDesktopWidth}px) {
    display: grid;
    grid-template-columns: 65px repeat(auto-fit, minmax(10px, 1fr));
    padding: 15px 0px;
    min-width: 900px;
  }
`;

const ColumnCell = styled.span`
  &.sortedBy {
    font-weight: 500;
  }
  &.leftPadding {
    padding-left: 5px;
  }
  font-size: 14px;
  line-height: 20px;
  padding-right: 10px;
  text-align: center;
  cursor: pointer;
  &:first-child {
    cursor: inherit;
  }
`;
const ColumnCellSubText = styled.div`
  font-size: 12px;
`;
const ColumnCellText = styled.span`
  &.sortedBy::after {
    content: url(${sortTriangle});
    display: inline-block;
    rotate: 0deg;
    padding: 0px 5px;
  }
  &.sortedBy.asc::after {
    rotate: 180deg;
  }
`;

const BaseRateHighlight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  background-color: ${tokens.BRAND_CHARGEBLUE};
  border-radius: 4px;
  height: 38px;
  padding: 0px 6px 0px 6px;
  text-align: center;
  width: 60px;

  div {
    line-height: 14px;
  }
  p {
    font-size: 11px;
    line-height: 13px;
    margin: 0px;
  }
`;

const DataRow = styled.div`
  display: grid;
  position: relative;
  font-size: 14px;
  line-height: 58px;
  height: 56px;
  transition: background-color 0.3s;
  transition-timing-function: ease-in-out;
  padding: 0px 10px;
  border: 1px solid rgba(208, 204, 195, 0);
  @media (min-width: ${minDesktopWidth}px) {
    line-height: 1rem;
    padding: 10px 0px;
    grid-template-columns: 65px repeat(auto-fit, minmax(10px, 1fr));
    min-width: 900px;
  }
  cursor: pointer;
  &:not(.after-selected) {
    border-top: 1px solid rgba(208, 204, 195, 0.5);
  }
  &.selected:not(.recommended) {
    border: 1px solid #1f2647;
    background-color: white;
    border-radius: 8px;
  }
  &.recommended {
    border: 1px solid ${tokens.INFO_90};
    background-color: ${tokens.INFO_70};
  }
`;
const DataCell = styled.span`
  &.radio {
    padding-left: 5px;
    & input {
      margin-top: 0px;
    }
  }
  &.left-bar {
    border-left: 2px solid darkgray;
  }

  padding-right: 15px;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: space-between;

  @media (min-width: ${minDesktopWidth}px) {
    text-align: left;
    justify-content: center;
  }
`;
const DataCellColumnText = styled.span`
  display: inline;
  margin-right: inherit;
  font-weight: 500;
  @media (min-width: ${minDesktopWidth}px) {
    display: none;
  }
`;
const DataCellText = styled.span`
  @media (min-width: ${minDesktopWidth}px) {
    &.cell3 {
      line-height: 1.4;
      padding: 4px 0;
    }
  }
`;
const DataCellRadio = styled.input`
  width: 22px;
  height: 22px;
  accent-color: black;
`;
const IconContainer = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  justify-content: center;
  align-items: center;
`;
const RecommendedIconArea = styled.div`
  position: absolute;
  left: 0;
`;
const RecommendedIcon = styled.img`
  display: block;
  height: 20px;
  width: 20px;
`;

const Rows = styled.div`
  display: contents;
  @media (min-width: ${minDesktopWidth}px) {
    max-height: 50vh;
  }
  overflow: auto;
`;

export {
  PricingColumns,
  changeOrderTypes,
  isChangeOrderType,
  sortByChangeOrderType,
};
