import { useEffect } from "react";
import { LoadingIndicator } from "@sunrun/experience-ui-components";
import { AuthError, signInWithRedirect } from "aws-amplify/auth";
import { useNavigate } from "react-router-dom";
import { PageCenter } from "components/templates/PageCenter";

const LoginPage = () => {
  const navigate = useNavigate();
  useEffect(() => {
    signInWithRedirect().catch((error) => {
      if (error instanceof AuthError) {
        if (error.name === "UserAlreadyAuthenticatedException") {
          navigate({ pathname: "/login/cognito" });
        }
      }
    });
  }, [navigate]);
  return (
    <PageCenter>
      <LoadingIndicator color="black" />
    </PageCenter>
  );
};

export { LoginPage };
