import styled from "@emotion/styled/macro";
import { useCallback, useEffect, useState } from "react";
import { uniqBy } from "lodash";
import { Modal, LoadingIndicator } from "@sunrun/experience-ui-components";
import camelCase from "lodash/camelCase";
import { CamelCase } from "type-fest";
import type { PricingOffer } from "../../../amplify/backend/function/offerexpstoreFrontApi/ts/public/offerTypes";
import { PricingPayment } from "components/organisms/PricingPayment";
import { PricingOptions } from "components/organisms/PricingOptions";
import {
  financialProductsOrderedList,
  getPricingFields,
} from "providers/pricing/getPricingFields";
import {
  ActiveFilters,
  Filters,
  FilterType,
  Group,
  Payload,
} from "providers/pricing/fields/Filters";
import {
  getPricePoints,
  getPricingOffer,
  onDomainEvent,
  submitOffer,
} from "services/storeFront";

import type {
  FinancialProduct,
  PricePoint,
  UnifiedPricingData,
} from "providers/pricing/PricePoints";
import { ErrorComponent } from "components/atoms/ErrorComponent";
import { createDesignImages } from "services/lightmileProject";
import { useAuthToken } from "hooks/useAuthToken";
import { getQueryStringParams } from "utils/queryString";
import { CurrentContractTerms } from "components/organisms/CurrentContractTerms";
import { CurrentHomeUpgradeSection } from "components/organisms/CurrentHomeUpgradeSection";

const pricePointsFilter = (
  name: string,
  pricePoints: PricePoint[],
  activeFilters: ActiveFilters,
  filters: Filters
) => {
  const activeFiltersWithDefaults = uniqBy(
    filters.reduce((activeAndDefaultFilters, filter) => {
      if (filter.type === FilterType.Dropdown) {
        return [
          ...activeAndDefaultFilters,
          {
            filter,
            payload: filter.defaultPayload(
              filter.payloads(
                pricePoints,
                filter.value as any,
                filter.displayFilter
              )
            ),
          },
        ];
      }
      return activeAndDefaultFilters;
    }, activeFilters as ActiveFilters),
    "filter"
  );
  const results = pricePoints.reduce((pricePoints, pricePoint) => {
    const passed = activeFiltersWithDefaults.reduce(
      (pass: boolean, activeFilter) => {
        switch (activeFilter.filter.type) {
          case FilterType.Dropdown:
            return (
              pass &&
              activeFilter.filter.value(pricePoint) ===
                activeFilter.payload.value
            );
          case FilterType.MinMaxSlider:
            const value = activeFilter.filter.value(pricePoint) as any;
            return (
              pass &&
              value >= activeFilter.payload.min &&
              value <= activeFilter.payload.max
            );
          default:
            return pass;
        }
      },
      true
    );

    if (passed) return [...pricePoints, pricePoint];
    return pricePoints;
  }, [] as PricePoint[]);
  return results;
};

type PricingPageProps = {
  lightmileProjectId?: string;
  repChangeOrder?: boolean;
  setChangeOrderError?: (err: Error) => void;
  offerId: string;
  prospectId: string;
  changeOrder: boolean;
  offerEvSpan: boolean;
  submittingOffer: boolean;
  onSubmitFailure: () => void;
  onSubmitComplete: () => void;
  onPricePointIdSelected: (pricePointId: string) => void;
};

const PricingPage = ({
  lightmileProjectId,
  offerId,
  prospectId,
  changeOrder,
  repChangeOrder = false,
  offerEvSpan,
  submittingOffer,
  onSubmitFailure,
  onSubmitComplete,
  onPricePointIdSelected,
  setChangeOrderError,
}: PricingPageProps) => {
  const [error, setError] = useState<Error>();
  const ihdToken = useAuthToken("IHD");
  const hybridToken = useAuthToken("HYBRID");
  const proposalToken = useAuthToken("PROPOSAL");
  const authToken = ihdToken ?? hybridToken ?? proposalToken;

  const host = getQueryStringParams()?.host?.toString() ?? "";
  const inLightmile = host === "LIGHTMILE";

  // The PricingPoint Data that comes from the server
  const [unifiedPricingData, setUnifiedPricingData] =
    useState<UnifiedPricingData>();

  // The PricePoints by Financial Product (just cash price point or loan price points)
  const [pricePoints, setPricePoints] = useState<PricePoint[]>();

  // The PricePoints Filtered by all groups
  const [filteredPricePoints, setFilteredPricePoints] =
    useState<PricePoint[]>();

  // The PricePoints Filtered by just the payment groups
  const [
    filteredPricePointsByGroupPayment,
    setFilteredPricePointsByGroupPayment,
  ] = useState<PricePoint[]>();

  const [financialProduct, setFinancialProduct] =
    useState<FinancialProduct>("Cash");

  const [availableFinancialProducts, setAvailableFinancialProducts] = useState<
    FinancialProduct[]
  >([...financialProductsOrderedList]);

  // These are the dynamic fields based on price point rules
  const [dynamicPricingFields, setDynamicPricingFields] =
    useState<ReturnType<typeof getPricingFields>>();

  const [activeFilters, setActiveFilters] = useState<ActiveFilters>([]);
  const [selectedPricePointId, setSelectedPricePointId] =
    useState<PricePoint["id"]>("");
  const [recommendedPricePointId, setRecommendedPricePointId] =
    useState<PricePoint["id"]>("");

  const shouldShowCommission = (inLightmile: boolean, changeOrder: boolean) => {
    if (inLightmile) return true;

    if (!inLightmile && !changeOrder) return false;

    return true;
  };

  const updateFinancialProduct = (financialProduct: FinancialProduct) => {
    setMatchCurrentTerms(false);
    setShowCommission(shouldShowCommission(inLightmile, changeOrder));
    setSelectedPricePointId("");
    setFinancialProduct(financialProduct);
  };

  const [submittingOfferMessage, setSubmittingOfferMessage] =
    useState<string>("");
  const [isLoadingPricePointData, setIsLoadingPricePointData] =
    useState<boolean>();

  const [pricingOffer, setPricingOffer] = useState<PricingOffer>();

  const [matchCurrentTerms, setMatchCurrentTerms] = useState(
    changeOrder && !repChangeOrder
  );

  const [showCommission, setShowCommission] = useState(
    shouldShowCommission(inLightmile, changeOrder)
  );

  // This is to reset filters if selected items change
  const [resetCounter, setResetCounter] = useState(0);

  const onApplyFilterFn = useCallback(
    (filter: Filters[0], payload: Payload) => {
      setMatchCurrentTerms(false);
      setSelectedPricePointId("");
      let newActiveFilters = uniqBy(
        [
          {
            filter,
            payload,
          },
          ...activeFilters,
        ],
        (filter) => filter.filter.title + filter.filter.type
      );
      if (filter.resetsOtherFilters) {
        setResetCounter(resetCounter + 1);
        newActiveFilters = newActiveFilters.filter(
          (activeFilter) => activeFilter.filter.resetsOtherFilters
        );
      }
      setActiveFilters(newActiveFilters);
    },
    [setResetCounter, activeFilters, resetCounter]
  );

  const updateFilteredPricePoints = useCallback(
    (
      pricePoints: PricePoint[],
      activeFilters: ActiveFilters,
      pricingFields: ReturnType<typeof getPricingFields> | undefined,
      matchCurrentTerms: boolean
    ) => {
      if (!pricePoints) {
        return;
      }

      let pricePointsToFilter = pricePoints;
      if (matchCurrentTerms) {
        const greenChangeOrders = pricePointsToFilter.filter(
          (e) => e.changeOrder?.changeOrderType === "NO_CHANGE_ORDER"
        );
        if (greenChangeOrders.length > 0) {
          pricePointsToFilter = greenChangeOrders;
        } else {
          const yellowChangeOrders = pricePointsToFilter.filter(
            (e) => e.changeOrder?.changeOrderType === "CUSTOMER_CHANGE_ORDER"
          );
          pricePointsToFilter = yellowChangeOrders;
          // If there are no green and no yellow change orders, the list will be blank
        }
      }

      setFilteredPricePoints(
        pricePointsFilter(
          "all groups",
          pricePointsToFilter,
          activeFilters,
          pricingFields?.filters || []
        )
      );
      setFilteredPricePointsByGroupPayment(
        pricePointsFilter(
          "payment group",
          pricePoints,
          activeFilters.filter(
            (filter) => filter.filter.group === Group.Payment
          ),
          (pricingFields?.filters || []).filter(
            (filter) => filter.group === Group.Payment
          )
        )
      );
    },
    []
  );

  useEffect(() => {
    if (!error) {
      return;
    }
    onSubmitFailure();
  }, [error, onSubmitFailure]);

  useEffect(() => {
    onPricePointIdSelected(selectedPricePointId);
  }, [selectedPricePointId, onPricePointIdSelected]);

  const handleSubmitOfferSuccess = useCallback(
    (
      res: { data?: { submitOffer: { correlationId: string } } | null },
      setMessage = true
    ) => {
      const correlationId = res.data?.submitOffer.correlationId;
      if (correlationId) {
        if (setMessage) {
          setSubmittingOfferMessage("Waiting for your Offer to be Processed.");
        }
        onDomainEvent(correlationId).subscribe((data) => {
          const errors = data.data?.onDomainEvent.errors || [];
          if (errors.length) {
            setError(
              new Error(`Error Submitting Offer ${JSON.stringify(errors[0])}`)
            );
          } else {
            onSubmitComplete();
          }
        });
      } else {
        setError(
          new Error("The ID to check the progress of the offer was not found.")
        );
      }
    },
    [onSubmitComplete]
  );

  const handleOfferSubmitFail = useCallback(
    (error: any, retryCount = 0) => {
      const errorMessage = error.message;
      if (errorMessage.includes("Optimistic locking failed")) {
        const pattern = new RegExp(`${offerId} lockVersion (\\d*)`);
        const matchArray = errorMessage.match(pattern); // Returns [<full match>, <capture group 1>]
        if (matchArray && matchArray.length > 0) {
          const newLockVersion = matchArray[1];
          if (newLockVersion && newLockVersion.length > 0) {
            if (retryCount > 0) {
              setError(
                new Error(
                  "Error creating offer Reprocessing the request failed to correct the lockVersion"
                )
              );
            } else {
              setSubmittingOfferMessage(
                "There was an issue during the initial submission, we are automatically retrying."
              );
              submitOffer(
                offerId,
                selectedPricePointId,
                Number(newLockVersion),
                host,
                offerEvSpan
              ).then(
                (res) => handleSubmitOfferSuccess(res, false),
                (error) => handleOfferSubmitFail(error, retryCount + 1)
              );
            }
            return;
          }
        }
      }

      setError(new Error("Error creating offer", { cause: error }));
    },
    [handleSubmitOfferSuccess, host, offerEvSpan, offerId, selectedPricePointId]
  );

  useEffect(() => {
    if (!submittingOffer || !lightmileProjectId) {
      return;
    }
    if (pricingOffer?.lockVersion === undefined) {
      setError(
        new Error(
          "Error while submitting offer: Lock Version has not been set."
        )
      );
      return;
    }

    if (!authToken) {
      setError(
        new Error("Error while submitting offer: Missing cognito token.")
      );
      return;
    }

    setSubmittingOfferMessage("Submitting Offer");
    createDesignImages(authToken, prospectId, lightmileProjectId)
      .then(() =>
        submitOffer(
          offerId,
          selectedPricePointId,
          pricingOffer.lockVersion,
          host,
          offerEvSpan
        )
      )
      .then(handleSubmitOfferSuccess, handleOfferSubmitFail);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submittingOffer]);

  useEffect(() => {
    if (
      !matchCurrentTerms ||
      !dynamicPricingFields?.filters ||
      !pricingOffer?.existingContract
    ) {
      return;
    }

    setFinancialProduct(pricingOffer.existingContract.type);
    const newFilters = dynamicPricingFields.filters
      .filter(
        (e) =>
          e.currentContractValue &&
          e.currentContractValue(pricingOffer.existingContract!) !== undefined
      )
      .map((filter) => {
        const value = filter.currentContractValue!(
          pricingOffer.existingContract!
        );
        return {
          filter,
          payload: {
            text: filter.displayFilter(value),
            value,
          },
        };
      });
    setActiveFilters(newFilters);
  }, [dynamicPricingFields, matchCurrentTerms, pricingOffer]);

  useEffect(() => {
    if (!pricePoints) {
      return;
    }

    updateFilteredPricePoints(
      pricePoints,
      activeFilters,
      dynamicPricingFields,
      matchCurrentTerms
    );
  }, [
    pricePoints,
    activeFilters,
    dynamicPricingFields,
    matchCurrentTerms,
    updateFilteredPricePoints,
  ]);

  // Gets data
  useEffect(() => {
    if (!authToken || isLoadingPricePointData) {
      return;
    }
    if (pricePoints) {
      return;
    }
    if (error) {
      return;
    }

    setIsLoadingPricePointData(true);
    getPricingOffer(authToken.token, offerId)
      .then((data) => {
        setPricingOffer(data);
        return getPricePoints(offerId)
          .then((unifiedPricingData) => {
            setUnifiedPricingData(unifiedPricingData);
            const filteredAvailableFinancialProducts =
              financialProductsOrderedList.filter(
                (product) =>
                  unifiedPricingData[
                    camelCase(product) as CamelCase<FinancialProduct>
                  ]?.length
              );
            setAvailableFinancialProducts(filteredAvailableFinancialProducts);

            let availableFinancialProduct =
              filteredAvailableFinancialProducts[0];
            if (changeOrder && data.existingContract?.type) {
              availableFinancialProduct = data.existingContract.type;
            }
            setFinancialProduct(availableFinancialProduct);
            const financialProductCamelCase = camelCase(
              availableFinancialProduct
            ) as CamelCase<FinancialProduct>;

            try {
              const pricingFields = getPricingFields({
                customerState: data.opportunity.state,
                customerUtility: data.opportunity.utility,
                salesDivision: data.opportunity.salesDivision,
                unifiedPricingData,
                financialProduct: availableFinancialProduct,
                changeOrder,
                showCommission,
              });
              setDynamicPricingFields(pricingFields);

              const currentPricePoints =
                unifiedPricingData[financialProductCamelCase];
              setPricePoints(currentPricePoints);

              if (changeOrder && data?.existingContract) {
                const pricePoint = currentPricePoints.find(
                  (e) => e.changeOrder?.recommended === true
                )?.id;
                if (pricePoint) {
                  setRecommendedPricePointId(pricePoint);
                  setSelectedPricePointId(pricePoint);
                }
              } else {
                setRecommendedPricePointId("");
              }
            } catch (err) {
              setError(new Error(`${err}`));
            }

            setIsLoadingPricePointData(false);
          })
          .catch((error) => {
            setIsLoadingPricePointData(false);
            if (
              setChangeOrderError &&
              (error?.message?.includes(
                "ERROR: Lambda is initializing your function."
              ) ||
                error?.message?.includes("Execution timed out"))
            ) {
              setChangeOrderError(error);
              return;
            }
            setError(
              new Error("There was a problem fetching pricing.", {
                cause: error,
              })
            );
          });
      })
      .catch((error) => {
        setIsLoadingPricePointData(false);
        setError(
          new Error("There was a problem fetching the offer.", {
            cause: error,
          })
        );
      });
  }, [
    offerId,
    authToken,
    activeFilters,
    updateFilteredPricePoints,
    pricePoints,
    isLoadingPricePointData,
    error,
    changeOrder,
    showCommission,
    setChangeOrderError,
  ]);

  useEffect(() => {
    setActiveFilters([]);

    if (unifiedPricingData && pricingOffer?.opportunity) {
      try {
        const pricingFields = getPricingFields({
          customerState: pricingOffer.opportunity.state,
          customerUtility: pricingOffer.opportunity.utility,
          salesDivision: pricingOffer.opportunity.salesDivision,
          unifiedPricingData,
          financialProduct,
          changeOrder,
          showCommission,
        });
        setDynamicPricingFields(pricingFields);
        setPricePoints(
          unifiedPricingData[
            camelCase(financialProduct) as CamelCase<FinancialProduct>
          ]
        );
      } catch (err) {
        setError(new Error(`Error from getPricingFields: ${err}`));
      }
    }
  }, [
    unifiedPricingData,
    financialProduct,
    pricingOffer,
    changeOrder,
    showCommission,
  ]);

  return (
    <>
      {error && (
        <Modal onClose={() => setError(undefined)}>
          <ErrorComponent context="PricingPage" error={error}></ErrorComponent>
        </Modal>
      )}
      {!error && submittingOffer && (
        <div>
          <LoadingIndicator color="black" message={submittingOfferMessage} />
        </div>
      )}
      {!error && !unifiedPricingData && (
        <div>
          <LoadingIndicator color="black" message="Getting pricing..." />
        </div>
      )}
      {!submittingOffer && unifiedPricingData && (
        <Container>
          <ControlsArea>
            {pricingOffer?.existingContract && (
              <CurrentContractTerms
                contractTerms={pricingOffer.existingContract}
              />
            )}
            {repChangeOrder && <CurrentHomeUpgradeSection />}
            <PricingPayment
              resetCounter={resetCounter}
              availableFinancialProducts={availableFinancialProducts}
              selectedFinancialProduct={financialProduct}
              onFinancialProductChange={updateFinancialProduct}
              paymentOptions={dynamicPricingFields?.paymentOptions || []}
              filters={(dynamicPricingFields?.filters || []).filter(
                (pf) => pf.group === Group.Payment
              )}
              activeFilters={activeFilters}
              pricePoints={pricePoints || []}
              onApplyFilter={onApplyFilterFn}
            />
          </ControlsArea>
          <ResultsArea>
            {dynamicPricingFields && dynamicPricingFields.columns && (
              <PricingOptions
                resetCounter={resetCounter}
                onApplyFilter={onApplyFilterFn}
                paymentPricePoints={filteredPricePointsByGroupPayment || []}
                filteredPricePoints={filteredPricePoints || []}
                columns={dynamicPricingFields?.columns || []}
                filters={(dynamicPricingFields?.filters || []).filter(
                  (pf) => pf.group === Group.PricingOption
                )}
                activeFilters={activeFilters}
                onPricePointSelected={setSelectedPricePointId}
                pricePointSelected={selectedPricePointId}
                inLightmile={inLightmile}
                changeOrder={changeOrder}
                matchCurrentTerms={matchCurrentTerms}
                setMatchCurrentTerms={setMatchCurrentTerms}
                showCommission={showCommission}
                setShowCommission={setShowCommission}
                recommendedPricePointId={recommendedPricePointId}
                repChangeOrder={repChangeOrder}
              />
            )}
          </ResultsArea>
        </Container>
      )}
    </>
  );
};

const Container = styled.section`
  display: flex;
  flex-direction: column;
  gap: 25px;
  font-family: "Roobert";

  @media (min-width: 1200px) {
    flex-direction: row;
  }
`;
const ResultsArea = styled.div`
  flex: 2;
`;
const ControlsArea = styled.span`
  > * + * {
    margin-top: 1.5rem;
  }

  flex: 1;
  @media (min-width: 1200px) {
    max-width: 240px;
  }
`;

export { PricingPage };
