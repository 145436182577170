import {
  AmplifyDelete,
  AmplifyGet,
  AmplifyPatch,
  AmplifyPost,
} from "@sunrun/sales-experience-shared/amplify-api-wrapper";
import { type SplatProspectUpdate } from "../../amplify/backend/function/OfferExpUsage/ts/public/types";
import {
  CancelSiteSurveyAppointmentRequestData,
  SiteSurveySlotRequestData,
  SplatCheckoutDocumentType,
} from "../../amplify/backend/function/OfferExpCheckout/ts/public/types";
import { listOffers } from "./storeFront";
import { subscribeToAsyncRequest } from "utils/subscribeToAsyncRequest";
import { Attachment } from "utils/usageUtils";
import { retry } from "utils/async";
import { logger } from "providers/logger";

const patchUsage = async (
  jwt: string,
  prospectId: string,
  prospectData: Partial<SplatProspectUpdate>
): Promise<{
  asyncRequestId: string;
}> => {
  return await AmplifyPatch(
    "Patch Usage",
    "OfferExpApi",
    `/prospect/${prospectId}/usage`,
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
      body: prospectData,
    },
    logger
  );
};

const postAttachment = async (
  jwt: string,
  prospectId: string,
  attachment: Attachment
): Promise<any> => {
  const formData = new FormData();
  formData.append("documentBlob", attachment.documentBlob);
  formData.append("prospectId", prospectId);
  formData.append("contentType", attachment.contentType);
  formData.append("documentName", attachment.documentName);
  formData.append("documentType", attachment.documentType);

  return AmplifyPost(
    "Post Attachment",
    "OfferExpApi",
    `/prospect/${prospectId}/usage/attachments`,
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
        "Content-Type": `multipart/form-data`,
      },
      body: formData,
    },
    logger
  ).catch((err) => {
    const status = err?.response?.status || "";
    if (status === 408) {
      console.log(
        "initial attempt timed out, it will probably still go through anyways"
      );
      return;
    }
    throw err;
  });
};

const postCheckoutAttachment = async (
  authKey: string,
  prospectId: string,
  proposalId: string,
  attachment: Attachment
): Promise<any> => {
  const formData = new FormData();
  formData.append("documentBlob", attachment.documentBlob);
  formData.append("prospectId", prospectId);
  formData.append("proposalId", proposalId);
  formData.append("contentType", attachment.contentType);
  formData.append("documentName", attachment.documentName);
  formData.append("documentType", attachment.documentType);
  const attachmentUrlsAsyncResponse = await AmplifyPost<{
    asyncRequestId: string;
  }>(
    "Attachment Urls",
    "OfferExpApi",
    `/prospect/${prospectId}/proposals/${proposalId}/get-attachment-urls`,
    {
      headers: {
        Authorization: `Bearer ${authKey}`,
        "Content-Type": `multipart/form-data`,
      },
      body: formData,
    },
    logger
  );
  const attachmentUrls = await subscribeToAsyncRequest(
    authKey,
    attachmentUrlsAsyncResponse.asyncRequestId
  );

  formData.append("uploadUrls", JSON.stringify(attachmentUrls));

  const uploadUrl: string = await AmplifyPost(
    "Upload Attachment Blob",
    "OfferExpApi",
    `/prospect/${prospectId}/proposals/${proposalId}/upload-attachment-blob`,
    {
      headers: {
        Authorization: `Bearer ${authKey}`,
        "Content-Type": `multipart/form-data`,
      },
      body: formData,
    },
    logger
  );

  formData.append("uploadUrl", uploadUrl);

  const checkoutAttachmentAsyncResponse = await AmplifyPost<{
    asyncRequestId: string;
  }>(
    "Upload Attachment",
    "OfferExpApi",
    `/prospect/${prospectId}/proposals/${proposalId}/upload-attachment`,
    {
      headers: {
        Authorization: `Bearer ${authKey}`,
        "Content-Type": `multipart/form-data`,
      },
      body: formData,
    },
    logger
  );

  return await subscribeToAsyncRequest(
    authKey,
    checkoutAttachmentAsyncResponse.asyncRequestId
  );
};

const postCreditCheck = (
  jwt: string,
  prospectId: string,
  contactId: string,
  action: string
): Promise<{
  url?: string;
  success?: boolean;
}> => {
  return AmplifyPost(
    "Credit Check",
    "OfferExpApi",
    `/prospect/${prospectId}/creditCheck`,
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
      body: {
        contactId,
        action: action,
      },
    },
    logger
  );
};

const postLoanApplicationEmail = (
  jwt: string,
  prospectId: string,
  proposalId: string,
  coBorrowerId?: string | undefined
): Promise<any> => {
  let url = `/prospect/${prospectId}/proposal/${proposalId}/loan/application/send-email`;
  if (coBorrowerId) {
    url += `?coborrower=${coBorrowerId}`;
  }
  return AmplifyPost(
    "Send Loan Application Email",
    "OfferExpApi",
    url,
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    },
    logger
  );
};

const postPaymentRequestEmail = async (
  jwt: string,
  prospectId: string,
  paymentMode: string
): Promise<any> => {
  let url = `/prospect/${prospectId}/sendPaymentRequestEmail`;
  let body = {
    paymentMode: paymentMode,
  };
  return AmplifyPost(
    "Send Payment Request Email",
    "OfferExpApi",
    url,
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
      body,
    },
    logger
  );
};

const createSiteSurveyAppointment = async (
  authKey: string,
  prospectId: string,
  requestData: SiteSurveySlotRequestData
) => {
  const createSSApptAsyncResponse = await AmplifyPost<{
    asyncRequestId: string;
  }>(
    "Create Site Survey Appointment",
    "OfferExpApi",
    `/prospect/${prospectId}/site-survey/appointment`,
    {
      headers: {
        Authorization: `Bearer ${authKey}`,
      },
      body: requestData,
    },
    logger
  );

  return await subscribeToAsyncRequest(
    authKey,
    createSSApptAsyncResponse.asyncRequestId
  );
};

const cancelSiteSurveyAppointment = async (
  authKey: string,
  prospectId: string,
  appointmentId: string,
  requestData: CancelSiteSurveyAppointmentRequestData
) => {
  const cancelSSApptAsyncResponse = await AmplifyPatch<{
    asyncRequestId: string;
  }>(
    "Cancel Site Survey Appointment",
    "OfferExpApi",
    `/prospect/${prospectId}/site-survey/appointment/${appointmentId}/cancel-site-survey`,
    {
      headers: {
        Authorization: `Bearer ${authKey}`,
      },
      body: requestData,
    },
    logger
  );

  return await subscribeToAsyncRequest(
    authKey,
    cancelSSApptAsyncResponse.asyncRequestId
  );
};

const sendIpaEmail = async (
  jwt: string,
  prospectId: string,
  proposalId: string
) => {
  return await AmplifyGet<{
    status: string;
  }>(
    "Send IPA Email",
    "OfferExpApi",
    `/prospect/${prospectId}/proposals/${proposalId}/sendIlIpaEmail`,
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    },
    logger
  );
};

const sendLoanEmail = async (
  jwt: string,
  prospectId: string,
  proposalId: string,
  secondaryContactId?: string
) => {
  const sendLoanEmailResponse = await AmplifyPost<{
    status: string;
  }>(
    "Send Loan Email",
    "OfferExpApi",
    `/prospect/${prospectId}/proposals/${proposalId}/send-loan-email`,
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
      body: {
        secondaryContactId,
      },
    },
    logger
  );
  if (sendLoanEmailResponse.status === "FAILED") {
    throw new Error("Error sending loan document email.");
  }
  return sendLoanEmailResponse;
};

const signDocument = async (
  props: {
    authKey: string;
    prospectId: string;
    proposalId: string;
    documentType: SplatCheckoutDocumentType;
    eSignatureSource: string;
    eSignatureType: string;
    primaryContactId: string;
  },
  secondaryContactId?: string,
  action?: string
) => {
  const {
    authKey,
    prospectId,
    proposalId,
    documentType,
    eSignatureSource,
    eSignatureType,
    primaryContactId,
  } = props;
  const signDocumentAsyncResponse = await AmplifyPost<{
    asyncRequestId: string;
  }>(
    "Sign Document",
    "OfferExpApi",
    `/prospect/${prospectId}/proposals/${proposalId}/sign-document`,
    {
      headers: {
        Authorization: `Bearer ${authKey}`,
      },
      body: {
        action,
        documentType,
        eSignatureSource,
        eSignatureType,
        primaryContactId,
        secondaryContactId,
      },
    },
    logger
  );

  return await subscribeToAsyncRequest(
    authKey,
    signDocumentAsyncResponse.asyncRequestId
  );
};

const voidProposal = async ({
  authKey,
  prospectId,
  proposalId,
  cognitoToken,
  identity,
}: {
  authKey: string;
  prospectId: string;
  proposalId: string;
  identity: string;
  cognitoToken: string;
}): Promise<any> => {
  await AmplifyDelete(
    "Void Proposal",
    "OfferExpApi",
    `/void-proposals/prospect/${prospectId}/proposals/${proposalId}`,
    {
      headers: {
        Authorization: `Bearer ${authKey}`,
      },
    },
    logger
  );

  return retry({
    fn: async () => listOffers(cognitoToken, prospectId, identity),
    test: (value: unknown) => {
      return (
        Array.isArray(value) && !value.find((offer) => offer.id === proposalId)
      );
    },
  });
};

const voidAllProposals = async ({
  authKey,
  prospectId,
  identity,
  cognitoToken,
}: {
  authKey: string;
  prospectId: string;
  identity: string;
  cognitoToken: string;
}): Promise<any> => {
  await AmplifyDelete(
    "Void All Proposals",
    "OfferExpApi",
    `/void-proposals/prospect/${prospectId}`,
    {
      headers: {
        Authorization: `Bearer ${authKey}`,
      },
    },
    logger
  );
  return retry({
    fn: async () => listOffers(cognitoToken, prospectId, identity),
    test: (offers: unknown) => {
      return Array.isArray(offers) && offers?.length === 0;
    },
  });
};

const voidDocument = async (
  props: {
    authKey: string;
    prospectId: string;
    proposalId: string;
    documentType: SplatCheckoutDocumentType;
    eSignatureSource: string;
    eSignatureType: string;
    primaryContactId: string;
  },
  action?: string
) => {
  const {
    authKey,
    prospectId,
    proposalId,
    documentType,
    eSignatureSource,
    eSignatureType,
    primaryContactId,
  } = props;
  const voidDocumentAsyncResponse = await AmplifyPost<{
    asyncRequestId: string;
  }>(
    "Void Documents",
    "OfferExpApi",
    `/prospect/${prospectId}/proposals/${proposalId}/void-document`,
    {
      headers: {
        Authorization: `Bearer ${authKey}`,
      },
      body: {
        action,
        documentType,
        eSignatureSource,
        eSignatureType,
        primaryContactId,
      },
    },
    logger
  );
  return await subscribeToAsyncRequest(
    authKey,
    voidDocumentAsyncResponse.asyncRequestId
  );
};

const getDocumentStatus = async (props: {
  authKey: string;
  prospectId: string;
  proposalId: string;
  documentType: SplatCheckoutDocumentType;
}): Promise<any> => {
  const { authKey, prospectId, proposalId, documentType } = props;
  return await AmplifyGet(
    "Get Document Status",
    "OfferExpApi",
    `/prospect/${prospectId}/proposals/${proposalId}/document-status?documentType=${documentType}`,
    {
      headers: {
        Authorization: `Bearer ${authKey}`,
      },
    },
    logger
  );
};

export {
  cancelSiteSurveyAppointment,
  createSiteSurveyAppointment,
  getDocumentStatus,
  patchUsage,
  postAttachment,
  postCheckoutAttachment,
  postCreditCheck,
  postLoanApplicationEmail,
  postPaymentRequestEmail,
  sendIpaEmail,
  sendLoanEmail,
  signDocument,
  voidAllProposals,
  voidDocument,
  voidProposal,
};
