import styled from "@emotion/styled/macro";
import { tokens, Typography } from "@sunrun/experience-ui-components";
import { TitledSection } from "components/molecules/TitledSection";
import { useCapContext } from "cap/providers/CapContext";
import { formatCurrency } from "utils/formatHelpers";
import { LineItem } from "cap/capTypes";

const CurrentHomeUpgradeSection = () => {
  const { sow } = useCapContext();
  const customerOrSunrunManaged = (lineItem: LineItem): string =>
    lineItem?.line_item_assignment?.toLowerCase().includes("customer managed")
      ? "Customer"
      : "Sunrun";

  if (!sow) {
    return null;
  }

  const { workItems, priceOfAllWork, totalSubsidy, priceToCustomer } = sow;

  return (
    <TitledSection>
      <Typography size={tokens.FONT_SIZE_3}>Current home upgrade</Typography>
      <div>
        {workItems.map((workItem, index) => {
          const { lineItems } = workItem;
          return lineItems.map((lineItem: LineItem, lineItemIndex) => {
            const {
              line_item_id,
              line_item_type,
              line_item_price,
              line_item_subType,
            } = lineItem;

            return (
              <CustomField
                key={line_item_id}
                firstItem={index === 0 && lineItemIndex === 0}
              >
                <CenteredFlexDiv>
                  <Typography
                    size={tokens.FONT_SIZE_1}
                    color={tokens.TINTS_OFF_WHITE_20}
                  >
                    {line_item_subType}
                  </Typography>
                  <Typography
                    size={tokens.FONT_SIZE_0}
                    color={tokens.TINTS_HERO_BLUE_50}
                  >
                    {formatCurrency(line_item_price, 2)}
                  </Typography>
                </CenteredFlexDiv>
                <CenteredFlexDiv>
                  <Typography
                    size={tokens.LABEL_MD_FONT_SIZE}
                    color={tokens.TINTS_HERO_BLUE_50}
                  >
                    {line_item_type}
                  </Typography>
                  <Typography
                    size={tokens.LABEL_MD_FONT_SIZE}
                    color={tokens.TINTS_OFF_WHITE_20}
                  >
                    {customerOrSunrunManaged(lineItem)}
                  </Typography>
                </CenteredFlexDiv>
              </CustomField>
            );
          });
        })}
        <DarkBorderTop>
          <Typography
            size={tokens.FONT_SIZE_1}
            color={tokens.TINTS_OFF_WHITE_20}
          >
            Subtotal work needed
          </Typography>
          <Typography
            size={tokens.FONT_SIZE_0}
            color={tokens.TINTS_OFF_WHITE_20}
          >
            {formatCurrency(priceOfAllWork, 2)}
          </Typography>
        </DarkBorderTop>
        <FlexDivWithPadding>
          <Typography size={tokens.FONT_SIZE_1} color={tokens.ERROR_40}>
            Sunrun subsidy
          </Typography>
          <Typography size={tokens.FONT_SIZE_0} color={tokens.ERROR_40}>
            {`-${formatCurrency(totalSubsidy, 2)}`}
          </Typography>
        </FlexDivWithPadding>
        <FlexDivWithPadding>
          <Typography size={tokens.FONT_SIZE_1} fontWeight="bold">
            Total cost to customer
          </Typography>
          <Typography size={tokens.FONT_SIZE_0} fontWeight="bold">
            {formatCurrency(priceToCustomer, 2)}
          </Typography>
        </FlexDivWithPadding>
      </div>
    </TitledSection>
  );
};

const CustomField = styled.div<{ firstItem?: boolean }>`
  padding: 8px 0;
  border-top: 1px solid ${tokens.OFFWHITE_40};

  ${({ firstItem }) => (firstItem ? `border-top: none;` : "")};
`;

const CenteredFlexDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const FlexDivWithPadding = styled(CenteredFlexDiv)`
  padding: 8px 0;

  border-top: 1px solid ${tokens.OFFWHITE_40};
`;

const DarkBorderTop = styled(FlexDivWithPadding)`
  border-top: 1px solid ${tokens.BRAND_HEROBLUE};
`;

export { CurrentHomeUpgradeSection };
