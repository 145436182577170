import useSWR from "swr";
import { SplatProposal } from "../../amplify/backend/function/OfferExpContacts/ts/public/types";
import { getWithAuth } from "./dataUtils";
import type { Attachment } from "./usageUtils";
import type {
  SplatProspect,
  SplatContact,
  SplatProspectResponse,
  SplatUtilityResponse,
} from "providers/Types";
import { useAuthToken } from "hooks/useAuthToken";

const useUtilityData = (customerState: string, authKey: string) => {
  const { data, error, isLoading } = useSWR(
    ["Get Utility Data", `/utilities?state=${customerState}`, authKey],
    getWithAuth<SplatUtilityResponse[]>
  );

  return { data, error, isLoading };
};

const useUsageData = (
  prospectId: string | undefined
): {
  prospect?: SplatProspectResponse;
  attachments?: Attachment[];
  error?: Error;
  isLoading: boolean;
} => {
  const authToken = useAuthToken("HYBRID");
  const url = `/prospect/${prospectId}/usage`;
  const { data, error, isLoading } = useSWR(
    ["Get Usage Data", prospectId ? url : undefined, authToken?.token],
    getWithAuth<{ prospect: SplatProspectResponse; attachments: Attachment[] }>
  );

  let prospect, attachments;
  if (data) {
    prospect = data.prospect;
    attachments = data.attachments;
  }

  return { prospect: prospect, attachments: attachments, error, isLoading };
};

const useContactsData = (
  prospectId: string,
  hybridToken: string,
  proposalToken?: string
) => {
  const shouldFetch = (prospectId && hybridToken) || proposalToken;
  const {
    data: contacts,
    error: contactsError,
    isLoading: isContactsLoading,
    isValidating: isContactsValidating,
    mutate: refetch,
  } = useSWR(
    shouldFetch &&
      (hybridToken
        ? ["Get Contacts", `/prospect/${prospectId}/contacts`, hybridToken]
        : [
            "Get Customer Contacts",
            `/customer/${proposalToken}/contacts`,
            proposalToken,
          ]),
    getWithAuth<SplatContact[]>
  );

  return {
    contacts,
    contactsError,
    isContactsLoading,
    isContactsValidating,
    refetch,
  };
};

const useProspectData = (prospectId: string, authKey: string) => {
  return useSWR(
    ["Get Prospect", `/prospect/${prospectId}`, authKey],
    getWithAuth<SplatProspect>
  );
};

const useProposals = (
  prospectId: string,
  hybridToken: string,
  proposalToken?: string
) => {
  const shouldFetch = (prospectId && hybridToken) || proposalToken;
  return useSWR(
    shouldFetch &&
      (hybridToken
        ? ["Get Proposals", `/prospect/${prospectId}/proposals`, hybridToken]
        : [
            "Get Customer Proposals",
            `/customer/${proposalToken}/proposals`,
            proposalToken,
          ]),
    getWithAuth<Array<SplatProposal>>
  );
};

export {
  useUtilityData,
  useUsageData,
  useContactsData,
  useProspectData,
  useProposals,
};
