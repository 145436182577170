const COGNITO_CONFIG = {
  staging: {
    Auth: {
      Cognito: {
        userPoolClientId: "p7eb9gml07lkjpeh2od37f5gn",
        userPoolId: "us-west-2_jUyflLPDL",
        loginWith: {
          oauth: {
            domain: "dev-hub-domain.auth.us-west-2.amazoncognito.com",
            scopes: ["openid"],
            redirectSignIn: [`${window.origin}/login/cognito`],
            redirectSignOut: [`${window.origin}/`],
            responseType: "code" as "code",
          },
        },
      },
    },
  },
  production: {
    Auth: {
      Cognito: {
        userPoolClientId: "lppl5bti7vec8d3n2und78sal",
        userPoolId: "us-west-2_iqlQcOgjT",
        loginWith: {
          oauth: {
            domain: "prd-hub-domain.auth.us-west-2.amazoncognito.com",
            scopes: ["openid"],
            redirectSignIn: [`${window.origin}/login/cognito`],
            redirectSignOut: [`${window.origin}/`],
            responseType: "code" as "code",
          },
        },
      },
    },
  },
};

export { COGNITO_CONFIG };
