import useSWR from "swr";
import { UserProfile } from "@sunrun/sales-experience-shared";
import { getWithAuth } from "../../utils/dataUtils";
import { Cap, CapDoc, Sow } from "../capTypes";

const useCap = (
  hybridToken: string,
  proposalToken: string,
  prospectId?: string
) => {
  let url = `/prospect/${prospectId}/cap`;
  const shouldFetch = (prospectId && hybridToken) || proposalToken;
  const {
    data: cap,
    error: capError,
    isLoading: isCapLoading,
  } = useSWR(
    shouldFetch &&
      (hybridToken
        ? ["Get Cap", url, hybridToken]
        : [
            "Get Customer Cap",
            `/customer/${proposalToken}/cap`,
            proposalToken,
          ]),
    getWithAuth<Cap>
  );

  return { cap, capError, isCapLoading };
};

const useCapDocs = (
  hybridToken: string,
  prospectId?: string,
  proposalToken?: string
) => {
  let url = `/prospect/${prospectId}/getCapDocs`;
  const shouldFetch = (prospectId && hybridToken) || proposalToken;
  const {
    data: documents,
    error: documentsError,
    isLoading: isDocumentsLoading,
  } = useSWR(
    shouldFetch &&
      (hybridToken
        ? ["Get Cap Docs", url, hybridToken]
        : [
            "Get Customer Cap Docs",
            `/customer/${proposalToken}/capDocs`,
            proposalToken,
          ]),
    getWithAuth<CapDoc[]>
  );

  return { documents, documentsError, isDocumentsLoading };
};

const useSow = (
  hybridToken: string,
  prospectId?: string,
  proposalToken?: string
) => {
  let url = `/prospect/${prospectId}/getSow`;
  const shouldFetch = (prospectId && hybridToken) || proposalToken;
  let sow: undefined | Sow, sowError: any, isSowLoading: boolean;
  ({
    data: sow,
    error: sowError,
    isLoading: isSowLoading,
  } = useSWR(
    shouldFetch &&
      (hybridToken
        ? ["Get Sow", url, hybridToken]
        : [
            "Get Customer Sow",
            `/customer/${proposalToken}/getSow`,
            proposalToken,
          ]),
    getWithAuth<Sow>
  ));

  return { sow, sowError, isSowLoading };
};

const useUserProfile = (jwt: string) => {
  let url = `/getUserProfile`;
  const {
    data: userProfile,
    error: userProfileError,
    isLoading: userProfileLoading,
  } = useSWR(["Get User Profile", url, jwt], getWithAuth<UserProfile>);

  return { userProfile, userProfileError, userProfileLoading };
};

export { useUserProfile, useCap, useCapDocs, useSow };
