import { AmplifyPost } from "@sunrun/sales-experience-shared/amplify-api-wrapper";
import { VivintEligibilityResponse } from "../cap/capTypes";
import { logger } from "providers/logger";

const checkVSHEligibility = async (
  jwt: string,
  prospectId: string,
  author?: string | null
): Promise<VivintEligibilityResponse> => {
  const url = `/prospect/${prospectId}/vsh`;
  return await AmplifyPost(
    "Check VSH Eligibility",
    "OfferExpApi",
    url,
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
      body: {
        author: author ?? null,
      },
    },
    logger
  );
};
export { checkVSHEligibility };
