import {
  createBrowserRouter,
  createMemoryRouter,
  Outlet,
  RouteObject,
  useNavigate,
  useParams,
} from "react-router-dom";
import { PropsWithChildren, useEffect } from "react";
import { ConfirmInfoPage } from "./components/pages/ConfirmInfoPage";
import { UsagePage } from "./components/pages/UsagePage";
import { ConfirmPreferencesTaskView } from "./checkout/components/organisms/ConfirmPreferencesTaskView";
import { VerificationCallTaskView } from "./checkout/components/organisms/VerificationCallTaskView";
import { FinalizeWithPartnerTaskView } from "./checkout/components/organisms/FinalizeWithPartnerTaskView";
import { CreditCheckTaskView } from "./checkout/components/organisms/CreditCheckTaskView";
import { AutopayEnrollTaskView } from "./checkout/components/organisms/AutopayEnrollTaskView";
import { CapPage } from "./cap/components/pages/CapPage";
import { CapContextProvider } from "./cap/providers/CapContext";
import { ReadAndSignDocsTaskView } from "checkout/components/organisms/ReadAndSignDocsTaskView";
import { ConfirmUtilityTaskView } from "checkout/components/organisms/ConfirmUtilityTaskView";
import { isInIframe } from "utils/iframeUtils";
import { CreatePage } from "components/pages/CreatePage";
import { OfferListPage } from "components/pages/OfferListPage";
import { ReviewPage } from "components/pages/ReviewPage";
import { setBaseUrl, useSubscribe } from "utils/messages";
import { LoginPage } from "components/pages/LoginPage";
import { CognitoLoginPage } from "components/pages/CognitoLoginPage";
import { CheckoutMainPage } from "checkout/components/pages/CheckoutMainPage";
import { ConfirmNamesTaskView } from "checkout/components/organisms/ConfirmNamesTaskView";
import { CheckoutConfirmOfferPage } from "checkout/components/pages/CheckoutConfirmOfferPage";
import { CheckoutNoOfferPage } from "checkout/components/pages/CheckoutNoOfferPage";
import { getQueryStringParams } from "utils/queryString";
import { CheckoutDepositPage } from "checkout/components/pages/CheckoutDepositPage";
import { CheckoutContextProvider } from "checkout/providers/CheckoutContext";
import { NewOfferProvider } from "providers/NewOfferProvider";
import { LoanApprovalTaskView } from "checkout/components/organisms/LoanApprovalTaskView";
import { UsageFormContextProvider } from "providers/UsageFormContext";
import { SiteSurveyTaskView } from "checkout/components/organisms/SiteSurveyTaskView";
import { CopyProposalPage } from "components/pages/CopyProposalPage";
import { RepChangeOrderPricingPage } from "components/pages/RepChangeOrderPricingPage";

const Wrapper = ({ children }: PropsWithChildren) => {
  const { prospectId } = useParams();
  const navigate = useNavigate();
  useSubscribe((message) => {
    switch (message.type) {
      case "COMMAND:CHANGE_URL": {
        navigate({ pathname: message.payload.url, hash: message.payload.hash });
        break;
      }
    }
  });
  return (
    <NewOfferProvider prospectId={prospectId!}>{children}</NewOfferProvider>
  );
};

const DevWrapper = ({ children }: PropsWithChildren) => {
  const { prospectId } = useParams();
  useEffect(() => {
    setBaseUrl(`/prospect/${prospectId}/offer`);
  }, [prospectId]);
  return (
    <NewOfferProvider prospectId={prospectId!}>{children}</NewOfferProvider>
  );
};

const host = getQueryStringParams()?.host;
const builderOnly = host === "LIGHTMILE";

const routes: Array<RouteObject> = [
  {
    path: "/prospect/:prospectId",
    element: isInIframe() ? (
      <Wrapper>
        <Outlet />
      </Wrapper>
    ) : (
      <DevWrapper>
        <Outlet />
      </DevWrapper>
    ),
    children: [
      {
        path: "/prospect/:prospectId/offer/list",
        element: <OfferListPage />,
      },
      {
        path: "/prospect/:prospectId/offer/cap",
        element: (
          <CapContextProvider>
            <CapPage />
          </CapContextProvider>
        ),
      },
      {
        path: "/prospect/:prospectId/offer/build/:offerId?/:copyDesignOrProposalId?",
        element: <CreatePage builderOnly={builderOnly} />,
      },
      {
        path: "/prospect/:prospectId/offer/build/proposal/:proposalId?",
        element: <CopyProposalPage />,
      },
      {
        path: "/prospect/:prospectId/offer/rep-change-order",
        element: (
          <CapContextProvider>
            <RepChangeOrderPricingPage />
          </CapContextProvider>
        ),
      },
      {
        path: "/prospect/:prospectId/offer/change-order/:offerId?",
        element: <CreatePage builderOnly={builderOnly} changeOrder={true} />,
      },
      {
        path: "/prospect/:prospectId/offer/confirm",
        element: <ConfirmInfoPage />,
      },
      {
        path: "/prospect/:prospectId/offer/usage",
        element: (
          <UsageFormContextProvider>
            <UsagePage />
          </UsageFormContextProvider>
        ),
      },
      {
        path: "/prospect/:prospectId/offer/proposal/:proposalId?",
        element: <ReviewPage />,
        index: true,
      },
      {
        path: "/prospect/:prospectId/offer/:offerId?",
        element: <ReviewPage />,
        index: true,
      },
      {
        path: "/prospect/:prospectId/offer/:offerId?/checkout/confirm-offer",
        element: (
          <CheckoutContextProvider>
            <CheckoutConfirmOfferPage />
          </CheckoutContextProvider>
        ),
      },
      {
        path: "/prospect/:prospectId/offer/checkout/no-offer",
        element: <CheckoutNoOfferPage />,
      },
      {
        path: "/prospect/:prospectId/offer/:offerId/checkout",
        element: (
          <CheckoutContextProvider>
            <CheckoutMainPage />
          </CheckoutContextProvider>
        ),
        children: [
          {
            path: "/prospect/:prospectId/offer/:offerId/checkout/confirm-names",
            element: <ConfirmNamesTaskView />,
          },
          {
            path: "/prospect/:prospectId/offer/:offerId/checkout/credit-check",
            element: <CreditCheckTaskView />,
          },
          {
            path: "/prospect/:prospectId/offer/:offerId/checkout/loan-approval",
            element: <LoanApprovalTaskView />,
          },
          {
            path: "/prospect/:prospectId/offer/:offerId/checkout/design-preferences",
            element: <ConfirmPreferencesTaskView />,
          },
          {
            path: "/prospect/:prospectId/offer/:offerId/checkout/autopay",
            element: <AutopayEnrollTaskView />,
          },
          {
            path: "/prospect/:prospectId/offer/:offerId/checkout/site-survey",
            element: <SiteSurveyTaskView />,
          },
          {
            path: "/prospect/:prospectId/offer/:offerId/checkout/sign-docs",
            element: <ReadAndSignDocsTaskView />,
          },
          {
            path: "/prospect/:prospectId/offer/:offerId/checkout/pay-deposit",
            element: <CheckoutDepositPage />,
          },
          {
            path: "/prospect/:prospectId/offer/:offerId/checkout/confirm-utility",
            element: <ConfirmUtilityTaskView />,
          },
          {
            path: "/prospect/:prospectId/offer/:offerId/checkout/summary-call",
            element: <VerificationCallTaskView />,
          },
          {
            path: "/prospect/:prospectId/offer/:offerId/checkout/finalize-partner",
            element: <FinalizeWithPartnerTaskView />,
          },
        ],
      },
      {
        path: "*",
        element: <div />,
      },
    ],
  },
  {
    path: "/login/cognito",
    element: <CognitoLoginPage />,
  },
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "*",
    element: (
      <Wrapper>
        <h1>404 Not Found</h1>
      </Wrapper>
    ),
  },
];

let router = isInIframe()
  ? createMemoryRouter(routes, {
      initialEntries: [window.location],
      initialIndex: 0,
    })
  : createBrowserRouter(routes);

export { router };
