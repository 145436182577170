import useSWR from "swr";
import { AmplifyGet } from "@sunrun/sales-experience-shared/amplify-api-wrapper";
import { useAuthToken } from "./useAuthToken";
import { logger } from "providers/logger";

const useDesignPreferencesUrl = (prospectId: string | undefined) => {
  const hybridToken = useAuthToken("HYBRID");
  return useSWR(
    ["designPreferencesUrl", hybridToken, prospectId],
    async ([, authKey, prospectId]) => {
      if (!authKey) return null;
      if (!prospectId) return null;
      const resp: { url: string } = await AmplifyGet(
        "Get Design Preference URL",
        "OfferExpApi",
        `/prospect/${prospectId}/design-preferences-url`,
        {
          headers: {
            Authorization: `Bearer ${authKey.token}`,
            "X-Auth-Type": authKey.type,
          },
        },
        logger
      );
      return resp;
    }
  );
};

export { useDesignPreferencesUrl };
