import {
  AmplifyPatch,
  AmplifyPost,
} from "@sunrun/sales-experience-shared/amplify-api-wrapper";
import {
  SplatContact,
  NewSplatContact,
  ConfirmSplatContacts,
} from "../../amplify/backend/function/OfferExpContacts/ts/public/types";
import { logger } from "providers/logger";

const editContact = async (
  jwt: string,
  prospectId: string,
  contactId: string,
  customerPrimaryPhone: string,
  customerEmail: string,
  // Additional fields to be patched that are not required
  rest?: Record<string, unknown>
): Promise<string> => {
  return await AmplifyPatch<string>(
    "Edit Contact",
    "OfferExpApi",
    `/prospect/${prospectId}/contacts/${contactId}`,
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
      body: {
        customerPrimaryPhone,
        customerEmail,
        ...rest,
      },
    },
    logger
  );
};

const editContactRole = async (
  jwt: string,
  prospectId: string,
  contactId: string,
  customerPrimaryPhone: string,
  customerEmail: string,
  // Additional fields to be patched that are not required
  rest?: Record<string, unknown>
): Promise<string> => {
  return await AmplifyPatch<string>(
    "Edit Contact Role",
    "OfferExpApi",
    `/prospect/${prospectId}/contacts/${contactId}/role`,
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
      body: {
        customerPrimaryPhone,
        customerEmail,
        ...rest,
      },
    },
    logger
  );
};

const confirmContacts = async (
  jwt: string,
  prospectId: string,
  proposalId: string,
  contacts: ConfirmSplatContacts
): Promise<string> => {
  return await AmplifyPost<string>(
    "Confirm Contacts",
    "OfferExpApi",
    `/prospect/${prospectId}/contacts/confirm`,
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
      body: {
        proposalId,
        contacts,
      },
    },
    logger
  );
};

const addContact = async (
  jwt: string,
  prospectId: string,
  contact: NewSplatContact
): Promise<SplatContact> => {
  return await AmplifyPost<SplatContact>(
    "Add Contact",
    "OfferExpApi",
    `/prospect/${prospectId}/contacts/`,
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
      body: contact,
    },
    logger
  );
};

export { editContact, editContactRole, addContact, confirmContacts };
