import jwtDecode from "jwt-decode";
import { fetchAuthSession } from "aws-amplify/auth";
import { CognitoToken } from "slices/authSlice";

const STORAGE_KEY = "cognitoTokenData";

function getCognitoToken() {
  const tokenDataString = localStorage.getItem(STORAGE_KEY);
  if (!tokenDataString) {
    return null;
  }
  const tokenData: CognitoToken = JSON.parse(tokenDataString);
  if (
    tokenData &&
    tokenData.token &&
    tokenData.token.length > 0 &&
    Date.now() < tokenData.expiration
  ) {
    return tokenData;
  }
  return null;
}

function validateCognitoToken() {
  const tokenDataString = localStorage.getItem(STORAGE_KEY);
  if (!tokenDataString) {
    return false;
  }
  const tokenData: CognitoToken = JSON.parse(tokenDataString);
  if (tokenData.expiration < Date.now()) {
    return false;
  }
  return true;
}

function setCognitoToken(tokenData: CognitoToken) {
  localStorage.setItem(STORAGE_KEY, JSON.stringify(tokenData));
}

async function refreshCognitoToken() {
  const data = await fetchAuthSession();
  if (!data.tokens?.idToken) {
    return;
  }
  return {
    token: data.tokens.idToken.toString(),
    expiration: (data.tokens.idToken.payload.exp ?? 0) * 1000, // Converting Unix timestamp to JS milliseconds
  };
}

function getUserInfo(tokenData: CognitoToken): { email: string } | undefined {
  if (!tokenData) {
    return undefined;
  }
  return jwtDecode(tokenData.token) as any;
}

export type { CognitoToken };
export {
  getCognitoToken,
  getUserInfo,
  refreshCognitoToken,
  setCognitoToken,
  validateCognitoToken,
};
