import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { getSfdcEnv } from "@sunrun/sales-experience-shared";
import { SfdcEnv } from "@sunrun/experience-ui-components";
import { rollbar } from "../../providers/rollbar";
import { useAppSelector } from "../../store";
import { useCap, useCapDocs, useSow, useUserProfile } from "../hooks/useCapSwr";
import { Cap, CapDoc, CapType, Proposal, Sow } from "../capTypes";
import {
  SplatContact,
  SplatProposal,
} from "../../../amplify/backend/function/OfferExpContacts/ts/public/types";
import { useContactsData, useProposals } from "../../utils/swrHooks";
import {
  useCheckoutAttachments,
  useOfferList,
  useProspect,
} from "../../checkout/hooks/useCheckoutSwr";
import { ListOffer } from "../../../amplify/backend/function/offerexpstoreFrontApi/ts/public/offerTypes";
import { getEnv } from "utils/env";

interface CapContextType {
  activeProposal: Proposal | undefined;
  activeProposalDesignImages: any;
  activeProposalError: any;
  activeProposalLoading: boolean;
  cap: Cap | undefined;
  capError: any;
  capTask: any;
  contacts: SplatContact[] | undefined;
  contactsError: any;
  documents: CapDoc[] | undefined;
  documentsError: any;
  equipmentLocationImages: any;
  isCapLoading: boolean;
  isCustomer: boolean;
  isContactsLoading: boolean;
  isDocumentsLoading: boolean;
  isOfferListLoading: boolean;
  isProposalsLoading: boolean;
  isSowLoading: boolean;
  offerListError: any;
  oneBaseUrl: string;
  originalProposal: SplatProposal | undefined;
  originalProposalDesignImages: any;
  originalProposalDesignImagesError: any;
  originalProposalDesignImagesLoading: boolean;
  proposalsError: any;
  prospect: any | undefined;
  prospectError: any;
  prospectId: string | undefined;
  prospectLoading: boolean;
  reviewOfferId: string;
  sfdcUrl: string;
  sow: Sow | undefined;
  sowError: any;
  splatBaseUrl: string;
  userProfileError: any;
  userProfileLoading: boolean;
}

const CapContext = createContext<CapContextType>({
  activeProposal: undefined,
  activeProposalDesignImages: undefined,
  activeProposalError: undefined,
  activeProposalLoading: true,
  cap: undefined,
  capError: undefined,
  capTask: undefined,
  contacts: undefined,
  contactsError: undefined,
  documents: [],
  documentsError: undefined,
  equipmentLocationImages: undefined,
  isCapLoading: true,
  isContactsLoading: true,
  isCustomer: false,
  isDocumentsLoading: true,
  isOfferListLoading: true,
  isProposalsLoading: true,
  isSowLoading: true,
  offerListError: undefined,
  oneBaseUrl: "",
  originalProposal: undefined,
  originalProposalDesignImages: undefined,
  originalProposalDesignImagesError: undefined,
  originalProposalDesignImagesLoading: true,
  proposalsError: undefined,
  prospect: undefined,
  prospectError: undefined,
  prospectId: undefined,
  prospectLoading: true,
  reviewOfferId: "",
  sfdcUrl: "",
  sow: undefined,
  sowError: undefined,
  splatBaseUrl: "",
  userProfileError: undefined,
  userProfileLoading: true,
});

const useCapContext = (): CapContextType => {
  const context = useContext(CapContext);
  if (!context) {
    rollbar.error(
      "[<CapContext>] -- useCapContext must be used within a CapContextProvider"
    );
    throw new Error("useCapContext must be used within a CapContextProvider");
  }
  return context;
};

const CapContextProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const { prospectId } = useParams();
  const authKey = useAppSelector((state) => state?.auth?.hybridToken);
  const cognitoToken = useAppSelector((state) => state?.auth?.cognitoToken);
  const proposalToken = useAppSelector((state) => state?.auth?.proposalToken);
  const {
    data: prospect,
    error: prospectError,
    isLoading: prospectLoading,
  } = useProspect(prospectId);
  const { cap, capError, isCapLoading } = useCap(
    authKey,
    proposalToken,
    prospectId
  );
  const capTask = cap?.salesCapTasks?.find?.(
    (task: any) => task.status !== "Inactive"
  );
  const capName = capTask?.name;
  const capLetterType = capName?.match(/\(([A-Z])\)$/)?.[1] as CapType;

  if (cap) {
    cap.type = capLetterType;
  }
  const { userProfile, userProfileError, userProfileLoading } =
    useUserProfile(authKey);

  let ENV: any = process.env.NODE_ENV ?? "";
  try {
    ENV = getEnv();
  } catch (e) {
    console.error("get environment error", e);
  }

  const {
    oneBaseUrl,
    salesforceBaseUrl,
    sfPartnerBaseUrl,
    sfdcEnv,
    splatBaseUrl,
  } = useMemo(() => {
    return {
      splatBaseUrl:
        ENV === "production"
          ? "https://go.sunrun.com"
          : "https://majstg-go.sunrun.com",
      oneBaseUrl:
        ENV === "production"
          ? "https://sunrunone.com"
          : "https://staging.sunrunone.com",
      salesforceBaseUrl:
        ENV === "production"
          ? "https://sunrun.my.salesforce.com"
          : "https://sunrun--majstg.sandbox.my.salesforce.com",
      sfPartnerBaseUrl:
        ENV === "production"
          ? "https://sunrun.my.site.com"
          : "https://sunrun--majstg.sandbox.my.site.com",
      sfdcEnv: (userProfile && getSfdcEnv(userProfile)) || "SFDC",
    };
  }, [ENV, userProfile]);

  const getSfdcBaseUrl = (
    sfdcEnv: SfdcEnv,
    sfPartnerBaseUrl: string,
    salesforceBaseUrl: string
  ): string => {
    let result = "";
    if (!sfdcEnv) return result;
    switch (sfdcEnv) {
      case "Community": {
        result = `${sfPartnerBaseUrl}/partners`;
        break;
      }
      case "D2HCommunity": {
        result = `${sfPartnerBaseUrl}/d2hpartnercommunity`;
        break;
      }
      case "SFDC":
      case "None":
      default: {
        result = salesforceBaseUrl;
        break;
      }
    }
    return result;
  };

  const { contacts, contactsError, isContactsLoading } = useContactsData(
    prospectId ?? "",
    authKey,
    proposalToken
  );
  const { documents, documentsError, isDocumentsLoading } = useCapDocs(
    authKey,
    prospectId,
    proposalToken
  );
  const { sow, sowError, isSowLoading } = useSow(
    authKey,
    prospectId ?? "",
    proposalToken
  );
  const activeProposal = sow?.proposal;
  const {
    data: activeProposalDesignImages,
    error: activeProposalError,
    isLoading: activeProposalLoading,
  } = useCheckoutAttachments(prospectId!, sow?.proposalName!, "Design Image");

  const [reviewOfferId, setReviewOfferId] = useState("");

  const { offerList, offerListError, isOfferListLoading } = useOfferList(
    prospectId ?? "",
    cognitoToken?.token,
    proposalToken
  );

  const equipmentLocationImages = sow?.allSharinPixImages.filter(
    (image: any) => {
      return (
        image.sharinpix__DisplayTags__c?.includes("CAP Design Summary") &&
        (image.sharinpix__DisplayTags__c
          ?.toLowerCase()
          .includes("equipment location") ||
          image.sharinpix__DisplayTags__c?.includes("Equipment"))
      );
    }
  );

  useEffect(() => {
    if (prospectId && offerList && offerList.length > 0) {
      (offerList as ListOffer[]).forEach((offer) => {
        if (offer.isSigned && offer.status !== "INACTIVE") {
          setReviewOfferId(offer.id);
        }
      });
    }
  }, [offerList, prospectId]);

  const {
    data: proposals,
    error: proposalsError,
    isLoading: isProposalsLoading,
  } = useProposals(prospectId!, authKey, proposalToken);

  const findOriginalProposal = () => {
    if (proposals?.length! > 0 && sow?.lastCustomerSignedProposalId) {
      return proposals?.filter((p: SplatProposal) => {
        return p.id === sow.lastCustomerSignedProposalId;
      })[0];
    }
  };

  const originalProposal = findOriginalProposal();

  const {
    data: originalProposalDesignImages,
    error: originalProposalDesignImagesError,
    isLoading: originalProposalDesignImagesLoading,
  } = useCheckoutAttachments(
    prospectId!,
    originalProposal?.proposalId ?? "",
    "Design Image"
  );

  const sfdcUrl =
    getSfdcBaseUrl(sfdcEnv, sfPartnerBaseUrl, salesforceBaseUrl) ?? "";

  const capContextValue = useMemo(() => {
    return {
      activeProposal,
      activeProposalDesignImages,
      activeProposalError,
      activeProposalLoading,
      cap,
      capError,
      capTask,
      contacts,
      contactsError,
      documents,
      documentsError,
      equipmentLocationImages,
      isCapLoading,
      isContactsLoading,
      isCustomer: proposalToken && !authKey,
      isDocumentsLoading,
      isOfferListLoading,
      isProposalsLoading,
      isSowLoading,
      offerListError,
      oneBaseUrl,
      originalProposal,
      originalProposalDesignImages,
      originalProposalDesignImagesError,
      originalProposalDesignImagesLoading,
      proposalsError,
      prospect,
      prospectError,
      prospectId,
      prospectLoading,
      reviewOfferId,
      sfdcUrl,
      sow,
      sowError,
      splatBaseUrl,
      userProfileError,
      userProfileLoading,
    } as CapContextType;
  }, [
    activeProposal,
    activeProposalDesignImages,
    activeProposalError,
    activeProposalLoading,
    authKey,
    cap,
    capError,
    capTask,
    contacts,
    contactsError,
    documents,
    documentsError,
    equipmentLocationImages,
    isCapLoading,
    isContactsLoading,
    isDocumentsLoading,
    isOfferListLoading,
    isProposalsLoading,
    isSowLoading,
    offerListError,
    oneBaseUrl,
    originalProposal,
    originalProposalDesignImages,
    originalProposalDesignImagesError,
    originalProposalDesignImagesLoading,
    proposalToken,
    proposalsError,
    prospect,
    prospectError,
    prospectId,
    prospectLoading,
    reviewOfferId,
    sfdcUrl,
    sow,
    sowError,
    splatBaseUrl,
    userProfileError,
    userProfileLoading,
  ]);

  return (
    <CapContext.Provider value={capContextValue}>
      {children}
    </CapContext.Provider>
  );
};

export { useCapContext, CapContextProvider };
export type { CapContextType };
