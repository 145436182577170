import { AmplifyPost } from "@sunrun/sales-experience-shared/amplify-api-wrapper";
import { DocumentEmail } from "../capTypes";
import { logger } from "providers/logger";

const postProspectProposalEmail = async (
  jwt: string,
  prospectId: string,
  optionalNotes: string
): Promise<any> => {
  let url = `/prospect/${prospectId}/sendProposalEmail`;
  let body = {
    notes: optionalNotes,
  };
  return AmplifyPost(
    "Send Proposal Email",
    "OfferExpApi",
    url,
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
      body,
    },
    logger
  );
};

const postDocumentEmail = async (
  jwt: string,
  document: DocumentEmail,
  prospectId: string,
  proposalToken: string
): Promise<any> => {
  let token = jwt;
  let url = `/prospect/${prospectId}/docEmail`;
  if (!jwt && proposalToken) {
    token = proposalToken;
    url = `/customer/${proposalToken}/docEmail`;
  }
  return AmplifyPost(
    "Send Proposal Email",
    "OfferExpApi",
    url,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: document,
    },
    logger
  );
};

export { postProspectProposalEmail, postDocumentEmail };
