import { Typography, Tooltip, Modal } from "@sunrun/experience-ui-components";
import styled from "@emotion/styled/macro";
import { ReactNode, useState } from "react";
import infoIconGray from "../assets/images/info-icon-gray.svg";
import {
  ModalButton,
  // eslint-disable-next-line import/no-restricted-paths
} from "./GlobalStyles";
import { formatCurrency } from "utils/formatHelpers";

type RightColProps = {
  cost: number | string;
  decimals?: number;
  unit?: string;
  maxCost?: number | string;
};
type RowContainerProps = RightColProps & {
  label: string;
  bold?: boolean;
};
type RowContainerWithTooltipProps = RowContainerProps & {
  description?: string;
  tooltip?: string;
};
type RowContainerWithModalProps = RowContainerProps & {
  modalDescription: ReactNode;
  ariaLabel: string;
};

const RightCol = ({ cost, decimals = 0, unit, maxCost }: RightColProps) => (
  <StyledRightCol>
    <StyledNumberField>
      {typeof cost === "string" ? cost : formatCurrency(cost, decimals)}
    </StyledNumberField>
    {!maxCost && unit && <span>{unit}</span>}
    {maxCost && (
      <div>
        up to{" "}
        {typeof maxCost === "string"
          ? maxCost
          : formatCurrency(maxCost, decimals)}
        {unit && <span>{unit}</span>}
      </div>
    )}
  </StyledRightCol>
);

const RowContainer = ({ label, bold, ...otherProps }: RowContainerProps) => (
  <StyledRowContainer>
    <div>
      <span className={bold ? "bold" : ""}>{label}: </span>
    </div>

    <RightCol {...otherProps} />
  </StyledRowContainer>
);

const RowContainerWithTooltip = ({
  tooltip,
  label,
  description,
  ...otherProps
}: RowContainerWithTooltipProps) => (
  <StyledRowContainer>
    <div style={{ flexGrow: 1 }}>
      <Tooltip content={tooltip}>
        <span>{label}: </span>
        <img alt="info icon tooltip" src={infoIconGray}></img>
      </Tooltip>
      {description && <Typography size="small">{description}</Typography>}
    </div>

    <RightCol {...otherProps} />
  </StyledRowContainer>
);

const RowContainerWithModal = ({
  modalDescription,
  label,
  ariaLabel,
  ...otherProps
}: RowContainerWithModalProps) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      {showModal && (
        <Modal title={label} onClose={() => setShowModal(false)}>
          <Typography as="p">{modalDescription}</Typography>
        </Modal>
      )}

      <StyledRowContainer>
        <div>
          <span>{label}: </span>
          <ModalButton
            onClick={() => setShowModal(true)}
            aria-label={ariaLabel}
          >
            <img alt="info icon tooltip" src={infoIconGray}></img>
          </ModalButton>
        </div>

        <RightCol {...otherProps} />
      </StyledRowContainer>
    </>
  );
};

const StyledRowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  margin: 16px 0;
  & .bold {
    font-weight: 600;
    font-size: 1.1rem;
  }
`;

const StyledRightCol = styled.div`
  text-align: right;
`;

const StyledNumberField = styled.span`
  display: block;
  font-size: 20px;
  font-style: bold;
  font-weight: 500;
  letter-spacing: -0.4px;
`;

export {
  RightCol,
  RowContainer,
  RowContainerWithTooltip,
  RowContainerWithModal,
};
