import useSWR from "swr";
import qs from "qs";
import { AmplifyGet } from "@sunrun/sales-experience-shared/amplify-api-wrapper";
import {
  AsyncRequestIdResponse,
  SiteSurveySlotsResponse,
  SiteSurveyAppointment,
} from "../../../amplify/backend/function/OfferExpCheckout/ts/public/types";
import { subscribeToAsyncRequest } from "utils/subscribeToAsyncRequest";
import { useAuthToken } from "hooks/useAuthToken";
import { getWithAuth } from "utils/dataUtils";
import { logger } from "providers/logger";

type SiteSurveySlot = {
  formVersion: number;
  gradeCategory: number;
  gradeRating: number;
  arrivalEndDate: string;
  arrivalStartDate: string;
  startTime: string;
  endTime: string;
};

const useSiteSurveySlots = (
  prospectId: string,
  startDate: string,
  endDate: string
) => {
  const SITE_SURVEY_SLOTS_URL = `/prospect/${prospectId}/site-survey/slot/`;
  const authToken = useAuthToken("HYBRID");
  const { data, error, isLoading, mutate } = useSWR(
    [SITE_SURVEY_SLOTS_URL, authToken, startDate, endDate],
    async ([url, authToken, startDate, endDate]) => {
      if (!authToken) return;
      const resp = (await AmplifyGet(
        "Get Site Survey Slots",
        "OfferExpApi",
        `${url}?${qs.stringify({ startDate, endDate })}`,
        {
          headers: {
            Authorization: `Bearer ${authToken.token}`,
            "X-Auth-Type": authToken.type,
          },
        },
        logger
      )) as AsyncRequestIdResponse;
      const siteSurveySlots = (await subscribeToAsyncRequest(
        authToken.token,
        resp.asyncRequestId
      )) as SiteSurveySlotsResponse;
      return siteSurveySlots as Array<SiteSurveySlot>;
    },
    {
      dedupingInterval: 1000 * 60 * 10,
      errorRetryCount: 1,
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnMount: true,
      revalidateOnReconnect: false,
      keepPreviousData: false,
      shouldRetryOnError: false,
    }
  );

  return {
    data,
    isLoading,
    error,
    retry: mutate,
  } as const;
};

const useSiteSurveyAppointments = (prospectId: string | undefined) => {
  const authToken = useAuthToken("HYBRID");
  const url = `/prospect/${prospectId}/site-survey/site-survey-appointments`;
  const { data, isLoading, error, mutate, isValidating } = useSWR(
    [
      "Get Site Survey Appointments",
      prospectId ? url : undefined,
      authToken?.token,
    ],
    getWithAuth<SiteSurveyAppointment[]>
  );

  const siteSurveyScheduled = data?.find(
    (appt: SiteSurveyAppointment) => appt.status === "Scheduled"
  );

  return {
    siteSurveyAppointment: siteSurveyScheduled,
    siteSurveyAppointmentsLoading: isLoading || isValidating,
    siteSurveyApointmentError: error,
    mutateSiteSurveyAppointments: mutate,
  };
};

export { useSiteSurveySlots, useSiteSurveyAppointments };
export type { SiteSurveySlot };
