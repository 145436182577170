import { useEffect } from "react";
import { Modal } from "@sunrun/experience-ui-components";

type ChangeOrderDelayModalType = {
  setWarning: (arg: null | Error) => void;
};

const ChangeOrderDelayModal = ({ setWarning }: ChangeOrderDelayModalType) => {
  const SECOND = 1000;
  useEffect(() => {
    // This timeout counts 15 seconds before closing the modal, which will trigger the PricingPage to relaunch
    const timeout = setTimeout(() => {
      setWarning(null);
    }, 15 * SECOND);

    return () => {
      clearInterval(timeout);
    };
  }, [setWarning]);

  return (
    <Modal
      title="Your price point data is still generating."
      type="warning"
      onClose={() => setWarning(null)}
    >
      Your price point data is still generating. This may take a couple minutes
      to resolve. We will re-check every 15 seconds to see if it's ready.
    </Modal>
  );
};

export { ChangeOrderDelayModal };
