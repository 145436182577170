import leafletHelpers from "@sunrun/leaflet-helpers";
import {
  AmplifyGet,
  AmplifyPost,
} from "@sunrun/sales-experience-shared/amplify-api-wrapper";
import axios from "axios";
import { logger } from "providers/logger";
import { type AuthToken } from "utils/authUtils";

const getSite = async (
  authToken: AuthToken,
  prospectId: string,
  lightmileProjectId?: string
): Promise<any> => {
  const url = lightmileProjectId
    ? `/lightmile-project/prospect/${prospectId}/site/${lightmileProjectId}`
    : `/lightmile-project/prospect/${prospectId}/site`;
  return AmplifyGet(
    "Get Site",
    "OfferExpApi",
    `${url}?authType=${authToken.type}`,
    {
      headers: {
        Authorization: `Bearer ${authToken.token}`,
      },
    },
    logger
  );
};

const getDesign = async (
  authToken: AuthToken,
  lightmileProjectId: string
): Promise<any> => {
  return AmplifyGet(
    "Get Design",
    "OfferExpApi",
    `/lightmile-project/${lightmileProjectId}/design?authType=${authToken.type}`,
    {
      headers: {
        Authorization: `Bearer ${authToken.token}`,
      },
    },
    logger
  );
};

const captureDesignImage = async (
  site: unknown,
  design: unknown,
  type: "small" | "large" | "pins"
): Promise<Uint8Array> => {
  const styles = document.createElement("style");
  styles.innerHTML = `
    .leaflet-container-${type} {
      z-index: 0;
      height: ${type !== "small" ? 635 : 484}px;
      width: ${type !== "small" ? 842 : 580}px;
      overflow: hidden;
    }
  `;
  document.head.appendChild(styles);
  const baseNode = document.createElement("div");
  baseNode.classList.add(`leaflet-container-${type}`);
  document.body.appendChild(baseNode);
  let leafletMapConfig;
  let leafletCaptureConfig;
  switch (type) {
    case "small":
      leafletMapConfig = {
        baseNode,
        design,
        isEditMode: false,
        isForImageCapture: true,
        showDisabledRoofs: false,
        showEnabledRoofs: false,
        showFlushObstructions: false,
        showModules: true,
        showTrees: false,
        showUsableRoofAreas: false,
        showVerticalObstructions: false,
        site,
        zoomToFeatures: ["ENABLED_ROOFS", "DISABLED_ROOFS"],
      };
      leafletCaptureConfig = { site, design };
      break;
    case "large":
      leafletMapConfig = {
        baseNode,
        design,
        isEditMode: false,
        isForImageCapture: true,
        showDisabledRoofs: true,
        showEnabledRoofs: true,
        showFlushObstructions: true,
        showModules: true,
        showTrees: true,
        showUsableRoofAreas: false,
        showVerticalObstructions: true,
        site,
        zoomToFeatures: ["ENABLED_ROOFS", "DISABLED_ROOFS"],
        colorCodeRoofs: false,
      };
      leafletCaptureConfig = { site, design, showTrees: true };
      break;
    case "pins":
      leafletMapConfig = {
        baseNode,
        design,
        isEditMode: false,
        isForImageCapture: true,
        showDisabledRoofs: false,
        showEnabledRoofs: true,
        showFlushObstructions: true,
        showModules: true,
        showTrees: true,
        showUsableRoofAreas: false,
        showVerticalObstructions: true,
        site,
        zoomToFeatures: ["ENABLED_ROOFS"],
        showElectricalPanel: true,
        showInverters: true,
        showBatteries: true,
        showRoofs: true,
      };
      leafletCaptureConfig = {
        site,
        design,
        showTrees: true,
        showElectricalPanel: true,
        showInverters: true,
        showBatteries: true,
        showRoofs: true,
      };
      break;
  }
  const map = leafletHelpers.buildMap(leafletMapConfig);
  // HACK: Timeout needed to allow the image to be load. Removing this
  // setTimeout results in a design image with a blank/black background.
  // Ideally @sunrun/leaflet-helpers would handle this.
  await new Promise((resolve) => setTimeout(resolve, 100));
  const image = await leafletHelpers.captureImage({
    ...leafletCaptureConfig,
    map,
  });
  baseNode.remove();
  styles.remove();
  return image;
};

const createDesignImages = async (
  authToken: AuthToken,
  prospectId: string,
  lightmileProjectId: string
): Promise<void> => {
  try {
    const [site, design] = await Promise.all([
      getSite(authToken, prospectId, lightmileProjectId),
      getDesign(authToken, lightmileProjectId),
    ]);
    if (!site.baseImage.base64 && site.baseImage.url) {
      const baseImageData = await axios.get(site.baseImage.url, {
        responseType: "arraybuffer",
      });
      site.baseImage.base64 = Buffer.from(
        baseImageData.data,
        "binary"
      ).toString("base64");
    }
    let pinsImagePromise = Promise.resolve<Uint8Array | undefined>(undefined);
    // Only generate the pins image, if there are pins to display
    if (
      site.mainElectricalPanelLocation ||
      site.batteryLocations ||
      site.inverterLocations ||
      site.roofLocations
    ) {
      pinsImagePromise = captureDesignImage(site, design, "pins");
    }
    const [largeImage, smallImage, pinsImage] = await Promise.all([
      captureDesignImage(site, design, "large"),
      captureDesignImage(site, design, "small"),
      pinsImagePromise,
    ]);
    const formData = new FormData();
    formData.append(
      "approved-design-large.jpg",
      new Blob([largeImage]),
      "approved-design-large.jpg"
    );
    formData.append(
      "approved-design.jpg",
      new Blob([smallImage]),
      "approved-design.jpg"
    );
    if (pinsImage) {
      formData.append(
        "approved-design-pins.jpg",
        new Blob([pinsImage]),
        "approved-design-pins.jpg"
      );
    }
    return AmplifyPost(
      "Create Design Images",
      "OfferExpApi",
      `/lightmile-project/${lightmileProjectId}/design-images?authType=${authToken.type}`,
      {
        headers: {
          Authorization: `Bearer ${authToken.token}`,
          "Content-Type": `multipart/form-data`,
        },
        body: formData,
      },
      logger
    );
  } catch (err) {
    console.error(err);
    throw err;
  }
};

type GetDesignImagesResponse = {
  largeImageUrl: string;
  smallImageUrl: string;
};
const getDesignImages = (
  authToken: AuthToken,
  lightmileProjectId: string
): Promise<GetDesignImagesResponse> => {
  return AmplifyGet(
    "get",
    "OfferExpApi",
    `/lightmile-project/${lightmileProjectId}/design-images?authType=${authToken.type}`,
    {
      headers: {
        Authorization: `Bearer ${authToken.token}`,
      },
    },
    logger
  );
};

export { createDesignImages, getDesign, getDesignImages, getSite };
