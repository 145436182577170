import useSWR, { SWRConfiguration } from "swr";
import { getWithAuth } from "utils/dataUtils";
import { DesignRequest, SiteIhdResponse } from "providers/Types";
import { useAppSelector } from "store";

type DesignRequestResponse = {
  designRequest: DesignRequest;
  designRequests: Array<
    Pick<
      DesignRequest,
      "createdDate" | "notesToDesigner" | "priority" | "queue" | "status"
    >
  >;
  site: SiteIhdResponse;
  isDesignRequestRequired: boolean;
};

const useDesignRequest = (prospectId?: string, options?: SWRConfiguration) => {
  const hybridToken = useAppSelector((state) => state?.auth?.hybridToken);

  return useSWR(
    [`/prospect/${prospectId}/designRequests`, hybridToken],
    ([path, hybridToken]) => {
      if (!hybridToken) return;
      return getWithAuth<DesignRequestResponse>([
        "Get Design Request",
        path,
        hybridToken,
      ]);
    },
    options
  );
};

export { useDesignRequest };
export type { DesignRequestResponse };
