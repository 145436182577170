import styled from "@emotion/styled/macro";
import { css } from "@emotion/react";
import { ReactElement } from "react";
import { tokens } from "@sunrun/experience-ui-components";
import {
  contentPadding,
  contentPaddingMobile,
  maxWidth,
} from "../atoms/GlobalStyles"; // eslint-disable-line import/no-restricted-paths
import shareIcon from "../assets/images/share-icon.svg";
import refreshIcon from "../assets/images/refresh.svg";
import { HeaderContentLayoutContainer } from "components/organisms/HeaderContentLayoutContainer";
import { PositionedHeader } from "components/organisms/PositionedHeader";

type HeaderButton = {
  type: "primary" | "secondary" | "icon" | "iconAndText";
  text?: string;
  altText?: string;
  icon?: "share" | "refresh";
  onClick: () => void;
  disabled?: boolean;
  borderless?: boolean;
  LRPadding?: boolean;
  dataTestId?: string;
};

type HeaderContentLayoutProps = {
  content: ReactElement;
  title?: string;
  description?: ReactElement | string;
  kicker?: string;
  buttons?: HeaderButton[];
  smallPadding?: boolean;
  borderless?: boolean;
  refSetter?: (node: HTMLDivElement | null) => void;
};
const HeaderContentLayout = ({
  content,
  title,
  description,
  kicker,
  buttons,
  smallPadding,
  refSetter,
}: HeaderContentLayoutProps) => {
  return (
    <HeaderContentLayoutContainer>
      <PositionedHeader
        className={smallPadding ? "smallPadding" : ""}
        title={title}
        description={description}
        kicker={kicker}
      />
      <PageContent
        ref={refSetter}
        className={smallPadding ? "smallPadding" : ""}
      >
        {content}
      </PageContent>
      <HeaderActionArea
        smallPadding={smallPadding}
        buttons={buttons}
      ></HeaderActionArea>
    </HeaderContentLayoutContainer>
  );
};

const StdButton = css`
  font-size: 16px;
  border-radius: 28px;
  padding: 1rem 2rem;
  border: 1px solid ${tokens.BRAND_HERO_BLUE};
  background: none;
  color: ${tokens.BRAND_HERO_BLUE};

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  cursor: pointer;

  &.borderless {
    border-width: 0px;
  }

  &:hover {
    opacity: 0.6;
  }

  &[disabled],
  &[disabled]:hover {
    background: #bbbcc5;
    border: 1px solid #bbbcc5;
    opacity: 1;
    cursor: default;
  }

  > img {
    display: block;
  }
`;

const IconActionButton = styled.button`
  ${StdButton}
  padding: 0px;
`;

const SecondaryActionButton = styled.button`
  ${StdButton}
`;

const PrimaryActionButton = styled.button`
  ${StdButton}
  color: ${tokens.WHITE};
  background: ${tokens.BRAND_HERO_BLUE};
`;

const HeaderActionArea: React.FC<{
  buttons?: HeaderButton[];
  smallPadding?: boolean;
}> = ({ buttons, smallPadding }) => {
  return (
    <HeaderActionSection className={smallPadding ? "smallPadding" : ""}>
      {buttons &&
        buttons.map((button, index) => {
          if (button.type === "primary") {
            return (
              <PrimaryActionButton
                key={index}
                disabled={button.disabled}
                onClick={() => button.onClick()}
                data-testid={button.dataTestId}
              >
                {button.text}
              </PrimaryActionButton>
            );
          } else if (button.type === "secondary") {
            return (
              <SecondaryActionButton
                key={index}
                disabled={button.disabled}
                onClick={() => button.onClick()}
                data-testid={button.dataTestId}
              >
                {button.text}
              </SecondaryActionButton>
            );
          } else if (button.type === "icon") {
            let iconSource;
            let iconText;
            switch (button.icon) {
              case "share":
                iconSource = shareIcon;
                iconText = button.altText;
                break;
              case "refresh":
                iconSource = refreshIcon;
                iconText = button.altText;
                break;
            }
            return (
              <IconActionButton
                data-testid={index + "-icon-action-button"}
                key={index}
                disabled={button.disabled}
                onClick={() => button.onClick()}
                className={button.borderless ? "borderless" : ""}
              >
                <img alt={iconText} src={iconSource}></img>
                {button.text && <span>{button.text}</span>}
              </IconActionButton>
            );
          } else {
            return <div>Button {button.text} has an invalid type</div>;
          }
        })}
    </HeaderActionSection>
  );
};

const HeaderActionSection = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  grid-row: 1;
  align-self: center;
  gap: 16px;
  padding-right: ${contentPadding}px;

  .disabled {
    opacity: 0.6;
  }

  //mobile view has sticky action bar on bottom
  @media (max-width: ${maxWidth}px) {
    flex-direction: column;
    background-color: ${tokens.BACKGROUND_LIGHT};
    bottom: 0;
    padding-bottom: 16px;
    margin-top: 16px;
    position: sticky;
    grid-row: content-end;
    padding-right: ${contentPaddingMobile}px;
  }
`;

const PageContent = styled.div`
  grid-column: main / span 2;
  grid-row: header-end;
  overflow: auto;
  padding-left: ${contentPadding}px;
  padding-right: ${contentPadding}px;

  &.smallPadding {
    padding-left: ${contentPaddingMobile}px;
    padding-right: ${contentPaddingMobile}px;
  }

  @media (max-width: ${maxWidth}px) {
    padding-left: ${contentPaddingMobile}px;
    padding-right: ${contentPaddingMobile}px;
  }
`;

export type { HeaderButton };
export { HeaderContentLayout };
