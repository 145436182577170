import { useCallback, useEffect, useMemo, useState } from "react";
import styled from "@emotion/styled/macro";
import { useParams } from "react-router-dom";
import {
  Button,
  Modal,
  tokens,
  Typography,
  SvgIcon,
  SvgNames,
  LoadingIndicator,
} from "@sunrun/experience-ui-components";
import { useDesignRequest } from "../../hooks/useDesignRequest";
import { ListOffer } from "../../../amplify/backend/function/offerexpstoreFrontApi/ts/public/offerTypes";
import {
  HeaderButton,
  HeaderContentLayout,
} from "components/templates/HeaderContentLayout";
import { useAppSelector } from "store";
import {
  customerListOffers,
  deleteOffer,
  listOffers,
  syncSalesforce,
} from "services/storeFront";
import {
  useNavigateToConfirm,
  useNavigateToReview,
  useNavigateToBuild,
  reloadMFE,
} from "utils/useNavigateHelper";
import { ShareProspectForm } from "components/organisms/ShareProspectForm";
import {
  formatNumber,
  formatPercent,
  formatProductCost,
} from "utils/formatHelpers";
import { ErrorComponent } from "components/atoms/ErrorComponent";
import { useDesignImages } from "hooks/useDesignImages";
import { RemoveIcon } from "components/atoms/RemoveIcon";
import { sendTrackingEvent } from "services/fullstory";
import { DesignRequest } from "providers/Types";
import { financialProductDisplayText } from "constants/financialProducts";

type CreateOfferSplashPageProps = {
  bypassSiteModel: boolean;
  prospectId?: string;
  setBypassSiteModel: (value: boolean) => void;
  setError: React.Dispatch<React.SetStateAction<Error | undefined>>;
};

const CreateOfferSplashPage = ({
  bypassSiteModel,
  prospectId,
  setError,
  setBypassSiteModel,
}: CreateOfferSplashPageProps) => {
  const navigateToBuild = useNavigateToBuild();
  const navigateToConfirm = useNavigateToConfirm();

  const startClick = useCallback(() => {
    sendTrackingEvent("New Offer Start", { source: "Start" });
    navigateToBuild();
  }, [navigateToBuild]);

  const {
    data: {
      designRequest,
      site,
      designRequests,
      isDesignRequestRequired = true,
    } = {},
    error,
    isLoading,
  } = useDesignRequest(prospectId, { refreshInterval: 1000 * 60 });

  const isSiteModelReady: boolean = useMemo(
    () =>
      Boolean(
        (!isDesignRequestRequired ||
          (designRequest !== undefined &&
            designRequest !== null &&
            (["Design", "Redesign"] as Array<DesignRequest["queue"]>).includes(
              designRequest.queue
            ) &&
            designRequest.status === "Complete")) &&
          site
      ),
    [designRequest, isDesignRequestRequired, site]
  );

  const canBypassDesignRequest: boolean = useMemo(
    () =>
      Boolean(
        designRequest?.queue === "Redesign" &&
          designRequest?.status === "Failure" &&
          designRequests?.find(
            (dr) => dr.queue === "Design" && dr.status === "Complete"
          ) &&
          site
      ),
    [designRequest, designRequests, site]
  );

  if (isLoading) {
    return <LoadingIndicator fullScreen color="black" />;
  }

  if (error) {
    setError(error);
  }

  let title = "Your Site Model is Being Created";
  if (!site && designRequest?.status === "Complete") {
    title = "No Site Model Available";
  } else if (designRequest?.status === "Failure") {
    title = "Site Model Update Failed";
  } else if (site) {
    title = "Your Site Model is Getting Updated";
  }

  let designRequestInfo = (
    <SplashTitle>
      Your {designRequest?.queue ? `${designRequest.queue}` : "Design"}
      &nbsp;is {designRequest?.status ?? "Not Started"}
      {!site &&
        designRequest?.status === "Complete" &&
        ", but no Site Model exists!"}
    </SplashTitle>
  );
  if (designRequest?.status === "Failure") {
    designRequestInfo = (
      <SplashTitle>
        Please return to Confirm Info to see the failure reason.
      </SplashTitle>
    );
  } else if (!site && designRequest?.status === "Complete") {
    designRequestInfo = (
      <SplashTitle>
        Please return to Confirm Info and request an updated site model.
      </SplashTitle>
    );
  }

  const showSiteModelPrompt: boolean = !isSiteModelReady && !bypassSiteModel;

  return (
    <CreateOfferSplashPageContainer>
      <div>
        {showSiteModelPrompt && (
          <>
            <SplashSubTitle data-testid="oe-site-model-not-ready">
              {title}
            </SplashSubTitle>
            {designRequestInfo}
            {!!designRequest?.queuePosition &&
              designRequest?.queuePosition > 0 && (
                <SplashTitle>
                  {designRequest.queuePosition} in front of you in the queue
                </SplashTitle>
              )}
            <SplashActions>
              <CustomButton onClick={() => navigateToConfirm()}>
                Confirm Info
              </CustomButton>
              {canBypassDesignRequest && (
                <CustomButton onClick={() => setBypassSiteModel(true)}>
                  Proceed with Previous Site Model
                </CustomButton>
              )}
            </SplashActions>
          </>
        )}

        {!showSiteModelPrompt && (
          <>
            <SplashSubTitle>
              Let's get started on your first offer
            </SplashSubTitle>
            <StyledButton>
              <Button
                onClick={startClick}
                size="md"
                color="primary"
                style={{ width: 200 }}
                data-testid={"create-offer-btn"}
              >
                Create an Offer
              </Button>
            </StyledButton>
          </>
        )}
      </div>
    </CreateOfferSplashPageContainer>
  );
};

const IncludingFlex = () => {
  return (
    <Typography
      size="small"
      color={tokens.TINTS_OFF_WHITE_20}
      style={{ marginTop: 4 }}
    >{`Including ${financialProductDisplayText["FlexMonthly"]}`}</Typography>
  );
};

const NewOfferCard = () => {
  const navigateToBuild = useNavigateToBuild();

  return (
    <NewOfferCardContainer
      data-testid={"new-offer-btn"}
      onClick={() => {
        sendTrackingEvent("New Offer Start", { source: "Create New Offer" });
        navigateToBuild();
      }}
    >
      <SvgIcon name={SvgNames.Plus} width="25px" color={tokens.BLACK} />
      <Typography size={"large"}>Add New Offer</Typography>
    </NewOfferCardContainer>
  );
};
const OfferCard = ({
  offer,
  setIsModalLoading,
  setError,
}: {
  offer: ListOffer;
  setIsModalLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setError: React.Dispatch<React.SetStateAction<Error | undefined>>;
}) => {
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
    useState<boolean>(false);
  const navigateToReview = useNavigateToReview();
  const cognitoToken = useAppSelector(
    (state) => state.auth.cognitoToken?.token
  );
  const removeOffer = useCallback(() => {
    if (!cognitoToken) {
      return;
    }
    setIsModalLoading(true);
    setShowDeleteConfirmationModal(false);
    deleteOffer(cognitoToken, offer.id, offer.lockVersion).finally(() => {
      // Succuss or failure, reload the page to show the current state
      reloadMFE();
    });
  }, [
    cognitoToken,
    offer.id,
    offer.lockVersion,
    setIsModalLoading,
    setShowDeleteConfirmationModal,
  ]);
  const financing = useMemo(() => {
    switch (offer.financing) {
      case "Cash":
        return "Purchase";
      case "Loan":
        return "Loan";
      case "Monthly":
        return "Monthly";
      case "Prepaid":
        return "Pre-Paid";
      case "FlexMonthly":
        return financialProductDisplayText["FlexMonthly"];
    }
  }, [offer]);
  const designImages = useDesignImages(offer?.lightmileProjectId);
  const isFlex = offer.financing === "FlexMonthly";

  return (
    <OfferCardContainer>
      {showDeleteConfirmationModal && (
        <Modal
          type="warning"
          title="Are you sure you want to remove the offer?"
          onClose={() => setShowDeleteConfirmationModal(false)}
          primaryButton={{
            text: "Delete Offer",
            onClick: removeOffer,
          }}
          secondaryButton={{
            text: "Cancel",
            onClick: () => setShowDeleteConfirmationModal(false),
          }}
        ></Modal>
      )}
      <CardHeader designImage={designImages?.data?.smallImageUrl}>
        <HeaderLargeText>{offer.label}</HeaderLargeText>
      </CardHeader>
      <CardBody>
        <TwoColumnLayout>
          <div>
            <InfoTitle>Financing</InfoTitle>
            <div>{financing}</div>
          </div>
          {offer.payment && (
            <div>
              <InfoTitle>Payment Amount</InfoTitle>
              <div>{formatProductCost(offer.payment, offer.financing)}</div>
            </div>
          )}
          <div>
            <InfoTitle>Bundle</InfoTitle>
            <div>{offer.bundleName}</div>
          </div>
          {isFlex && (
            <>
              <div>
                <InfoTitle>Usage Offset</InfoTitle>
                <div data-testid="usage-offset-value">
                  {formatPercent((offer.usageOffset ?? 0) / 100, 0)}
                </div>
                <IncludingFlex />
              </div>
              <div>
                <InfoTitle>Exp. Annual Prod</InfoTitle>
                <div data-test-id="productionKwh">{`${formatNumber(
                  offer.production
                )} kWh`}</div>
                <IncludingFlex />
              </div>
            </>
          )}
          <div>
            <InfoTitle>Panels</InfoTitle>
            <div>{offer.solarCount}</div>
          </div>
        </TwoColumnLayout>
        <CardBreak />
        <TwoColumnLayout>
          {offer.products.map((product, index) => {
            return (
              <div key={product.name ?? index}>
                <InfoTitle>{product.name}</InfoTitle>
                <div>{product.count}</div>
              </div>
            );
          })}
        </TwoColumnLayout>
      </CardBody>
      <CardFooter>
        <CustomButton onClick={() => navigateToReview(offer.id)}>
          Review
        </CustomButton>
        <RemoveIcon onClick={() => setShowDeleteConfirmationModal(true)} />
      </CardFooter>
    </OfferCardContainer>
  );
};

const OfferList = ({
  offers,
  setIsModalLoading,
  setError,
}: {
  offers: ListOffer[];
  setIsModalLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setError: React.Dispatch<React.SetStateAction<Error | undefined>>;
}) => {
  return (
    <>
      <OfferListTitle>
        {offers.length} Offer{offers.length > 1 ? "s" : ""}
      </OfferListTitle>
      <OffersContainer>
        {offers.map((offer) => {
          return (
            <OfferCard
              key={offer.id}
              offer={offer}
              setIsModalLoading={setIsModalLoading}
              setError={setError}
            />
          );
        })}
        <NewOfferCard />
      </OffersContainer>
    </>
  );
};

const OfferListPage = () => {
  const { prospectId } = useParams();
  const cognitoToken = useAppSelector(
    (state) => state.auth.cognitoToken?.token
  );
  const proposalToken = useAppSelector((state) => state.auth.proposalToken);
  const identity = useAppSelector((state) => state.auth.identity);

  const [offers, setOffers] = useState<ListOffer[]>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isModalLoading, setIsModalLoading] = useState<boolean>(false);
  const [showShareModal, setShowShareModal] = useState<boolean>(false);
  const [error, setError] = useState<Error>();
  const [bypassSiteModel, setBypassSiteModel] = useState(false);

  const navigateToConfirm = useNavigateToConfirm();

  const onReloadData = useCallback(() => {
    if (((!cognitoToken || !identity) && !proposalToken) || !prospectId) return;
    setIsLoading(true);
    let request;
    if (cognitoToken && identity) {
      request = listOffers(cognitoToken, prospectId, identity);
    } else if (proposalToken) {
      request = customerListOffers(proposalToken);
    } else {
      setIsLoading(false);
      setError(new Error("Invalid Auth Token type!"));
      return;
    }

    request
      .then((data) => {
        if (data && data.length > 0) {
          setOffers(data);
        } else {
          setOffers([]);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [cognitoToken, proposalToken, prospectId, identity]);

  useEffect(() => {
    if ((!cognitoToken && !proposalToken) || !prospectId) return;
    onReloadData();
  }, [cognitoToken, proposalToken, prospectId, onReloadData]);
  const onShareClick = useCallback(() => {
    setShowShareModal(true);
  }, []);
  const closeShareModal = useCallback(() => {
    setShowShareModal(false);
  }, []);
  const onSendOffersStart = useCallback(() => {
    closeShareModal();
    setIsModalLoading(true);
  }, [closeShareModal]);
  const onSendOffers = useCallback(() => {
    // Succuss or failure, reload the page to show the current state
    reloadMFE();
  }, []);
  const syncSalesforceClick = useCallback(() => {
    if (!cognitoToken || !prospectId) return;
    setIsLoading(true);
    syncSalesforce(cognitoToken, prospectId).finally(() => {
      // Succuss or failure, reload the page to show the current state
      reloadMFE();
    });
  }, [cognitoToken, prospectId]);

  const hasOffers = useMemo(() => {
    return offers && offers.length > 0;
  }, [offers]);
  const { data: { designRequest } = {}, isLoading: designRequestIsLoading } =
    useDesignRequest(prospectId);
  const refreshText = "Refresh Offer List";
  const isDesignReady = designRequest?.status === "Complete";
  const showSplash: boolean =
    (!bypassSiteModel && !isDesignReady) || !hasOffers;

  const buttons = useMemo(() => {
    if (!hasOffers || !cognitoToken) {
      return [];
    }
    return [
      {
        type: "icon",
        icon: "refresh",
        text: refreshText,
        altText: "refresh",
        borderless: true,
        onClick: syncSalesforceClick,
      } as HeaderButton,
      {
        type: "icon",
        icon: "share",
        altText: "Share Offers",
        onClick: onShareClick,
      } as HeaderButton,
      {
        type: "secondary",
        text: "Back to Info & Tasks",
        onClick: navigateToConfirm,
        LRPadding: true,
      } as HeaderButton,
    ];
  }, [
    hasOffers,
    cognitoToken,
    onShareClick,
    navigateToConfirm,
    syncSalesforceClick,
  ]);

  if (error) {
    return (
      <HeaderContentLayout
        content={<ErrorComponent context="OfferListPage" error={error} />}
        title="There was a Problem."
        description="Something went wrong. Please try refreshing the page."
      />
    );
  }

  if (!prospectId) {
    return (
      <HeaderContentLayout
        content={
          <ErrorComponent
            context="OfferListPage"
            error={new Error(`${prospectId} not found`)}
          />
        }
        title="There was a Problem."
        description="Something went wrong. Please try refreshing the page."
      />
    );
  }
  if (isLoading || !offers || designRequestIsLoading) {
    return <LoadingIndicator fullScreen color="black" />;
  }
  return (
    <>
      {showSplash ? (
        <CreateOfferSplashPage
          prospectId={prospectId}
          setError={setError}
          bypassSiteModel={bypassSiteModel}
          setBypassSiteModel={setBypassSiteModel}
        />
      ) : (
        <HeaderContentLayout
          title="Offer Manager"
          buttons={buttons}
          content={
            <OfferList
              offers={offers}
              setIsModalLoading={setIsModalLoading}
              setError={setError}
            />
          }
        />
      )}

      {showShareModal && offers && (
        <Modal onClose={closeShareModal}>
          <ShareProspectForm
            prospectId={prospectId}
            offers={offers}
            customerName={offers[0].customerName}
            onSubmitStart={onSendOffersStart}
            onSubmitComplete={onSendOffers}
            closeModal={closeShareModal}
          />
        </Modal>
      )}
      {isModalLoading && (
        <Modal hideClose>
          <AlignCenter>
            <LoadingIndicator
              color="black"
              fullScreen={false}
            ></LoadingIndicator>
          </AlignCenter>
        </Modal>
      )}
    </>
  );
};

const SplashActions = styled.div`
  display: flex;
  gap: 14px;
  justify-content: center;
`;

const CreateOfferSplashPageContainer = styled.div`
  height: 100vh;
  width: 100%;
  background-image: linear-gradient(#a5c9ff, #fff 92%);

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const SplashTitle = styled.div`
  font-size: 20px;
  line-height: 24px;
  color: ${tokens.TEXT_LIGHT};
  padding-bottom: 24px;
`;

const SplashSubTitle = styled.div`
  max-width: 15em;
  font-size: 44px;
  line-height: 44px;
  color: ${tokens.BRAND_HERO_BLUE};
  padding-bottom: 24px;
`;

const OfferListTitle = styled.div`
  font-size: 20px;
  line-height: 24px;
`;

const OffersContainer = styled.div`
  display: flex;
  padding: 20px 0;
  gap: 16px;
  overflow-x: scroll;
`;

const NewOfferCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;

  border-radius: 8px;
  border: 1px dashed ${tokens.OFFWHITE_40};

  background-color: ${tokens.OFFWHITE_70};
  min-width: 320px;
  max-width: 320px;

  &:hover {
    background-color: ${tokens.OFFWHITE_80};
  }

  &:active {
    background-color: ${tokens.OFFWHITE_60};
  }

  cursor: pointer;

  & svg {
    border: 3px solid black;
    padding: 8px;
    border-radius: 36px;
  }
`;

const OfferCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: 1px solid ${tokens.OFFWHITE_40};
  background-color: ${tokens.OFFWHITE_80};
  min-width: 320px;
  max-width: 320px;
`;

const CardHeader = styled.div<{
  designImage: string | undefined;
}>`
  height: 148px;
  text-shadow: 0 0 10px ${tokens.BLACK};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: #515151;
  ${({ designImage }) => {
    return designImage
      ? `background-image: linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) ), url(${designImage});`
      : "";
  }}
  border-radius: 8px 8px 0px 0px;
  display: flex;
  flex-direction: column;
  justify-content: end;

  font-size: 12px;
  line-height: 20px;
  padding: 0 24px 12px 24px;
  color: ${tokens.WHITE};
`;

const HeaderLargeText = styled.div`
  font-size: 20px;
  line-height: 24px;
`;

const CardBody = styled.div`
  font-size: 14px;
  color: ${tokens.TINTS_OFF_WHITE_20};
  padding: 24px;
  flex-grow: 1;
`;

const InfoTitle = styled.div`
  color: ${tokens.TINTS_HERO_BLUE_50};
  line-height: 20px;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TwoColumnLayout = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  grid-gap: 1rem;
`;

const CardBreak = styled.div`
  border: 0.5px solid ${tokens.OFFWHITE_40};
  margin: 1rem 0;
`;

const CardFooter = styled.div`
  padding: 0 24px 24px;
  display: grid;
  grid-template-columns: 1fr auto auto;

  > :first-child {
    margin-right: 2rem;
  }
`;

const CustomButton = styled.button`
  font-size: 12px;
  padding: 12px 27px;
  border-radius: 28px;
  color: ${tokens.WHITE};
  border-color: ${tokens.BRAND_HERO_BLUE};
  background: ${tokens.BRAND_HERO_BLUE};
  cursor: pointer;

  &:hover {
    opacity: 0.6;
  }
`;

const AlignCenter = styled.div`
  text-align: center;
`;

const StyledButton = styled.div`
  display: inline-block;
`;

export { CreateOfferSplashPage, OfferListPage };
