import styled from "@emotion/styled/macro";
import {
  Checkbox,
  Switch,
  Typography,
  tokens,
} from "@sunrun/experience-ui-components";
import { useState } from "react";
import { PricingColumns } from "components/molecules/PricingColumns";
import { PricingFilters } from "components/molecules/PricingFilters";
import { TitledSection } from "components/molecules/TitledSection";
import { PricePoint } from "providers/pricing/PricePoints";
import { columns } from "providers/pricing/fields/Columns";
import { ActiveFilters, Filters } from "providers/pricing/fields/Filters";

type PricingOptionsProps = {
  paymentPricePoints: PricePoint[];
  filteredPricePoints: PricePoint[];
  columns: typeof columns;
  filters: Filters;
  activeFilters: ActiveFilters;
  onPricePointSelected: (pricePoint: PricePoint["id"]) => any;
  pricePointSelected: PricePoint["id"];
  onApplyFilter: (filter: Filters[0], payload: any) => void;
  resetCounter: number;
  inLightmile: boolean;
  changeOrder: boolean;
  matchCurrentTerms: boolean;
  setMatchCurrentTerms: (value: boolean) => void;
  showCommission: boolean;
  setShowCommission: (value: boolean) => void;
  recommendedPricePointId: PricePoint["id"];
  repChangeOrder?: boolean;
};

const PricingOptions = ({
  paymentPricePoints,
  filteredPricePoints,
  columns,
  filters,
  activeFilters,
  onPricePointSelected,
  pricePointSelected,
  onApplyFilter,
  resetCounter,
  inLightmile,
  changeOrder,
  matchCurrentTerms,
  setMatchCurrentTerms,
  showCommission,
  setShowCommission,
  recommendedPricePointId,
  repChangeOrder,
}: PricingOptionsProps) => {
  const [showFilters, setShowFilters] = useState<boolean>(false);
  return (
    <Container>
      <Title>
        <Typography variant="h6">Pricing Options</Typography>
        <ActionArea>
          {changeOrder && (
            <Checkbox
              label="Match Current Contract Terms"
              checked={matchCurrentTerms}
              onChange={() => setMatchCurrentTerms(!matchCurrentTerms)}
            />
          )}
          {!changeOrder && !inLightmile && (
            <SwitchContainer>
              <Typography fontWeight="medium">Presentation View</Typography>
              <Switch
                size="large"
                checked={!showCommission}
                onClick={() => {}}
                onChange={() => setShowCommission(!showCommission)}
                name="presentationView"
                testId="offer-experience--PricingOptions--PresentationView"
              />
            </SwitchContainer>
          )}
          {changeOrder && inLightmile && (
            <Checkbox
              label="Show Commission"
              checked={showCommission}
              onChange={() => setShowCommission(!showCommission)}
            />
          )}
          <ShowFilters
            onClick={() => {
              setShowFilters(!showFilters);
            }}
          >
            <Typography
              color={tokens.TINTS_OFF_WHITE_20}
              decoration="underline"
              size={12}
            >
              {showFilters && "Hide Filters"}
              {!showFilters && "Show Filters"}
            </Typography>
          </ShowFilters>
        </ActionArea>
      </Title>

      <Context>
        <FilterContainer className={showFilters ? "showFilters" : ""}>
          <PricingFilters
            resetCounter={resetCounter}
            pricePoints={paymentPricePoints}
            filters={filters}
            activeFilters={activeFilters}
            onApplyFilter={onApplyFilter}
          />
        </FilterContainer>
        <PricingColumns
          pricePoints={filteredPricePoints}
          columns={columns}
          onPricePointSelected={onPricePointSelected}
          pricePointSelected={pricePointSelected}
          recommendedPricePointId={recommendedPricePointId}
          repChangeOrder={repChangeOrder}
        />
      </Context>
    </Container>
  );
};

const Container = styled(TitledSection)``;

const Title = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const ActionArea = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  align-items: center;
`;

const SwitchContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

const FilterContainer = styled.div`
  transition: border-color 1s, 0.3s;
  overflow: hidden;
  transition-timing-function: ease-in-out;
  max-height: 0px;
  border: 1px solid rgba(0, 0, 0, 0);
  &.showFilters {
    max-height: 500px;
    padding-bottom: 25px;
    margin-bottom: 10px;
    border-bottom-color: rgba(0, 0, 0, 0.2);
  }
`;
const ShowFilters = styled.span`
  cursor: pointer;
`;

const Context = styled.div``;

export { PricingOptions };
