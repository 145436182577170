import { useEffect, useState } from "react";
import { useFlags } from "flagsmith/react";
import {
  Modal,
  LoadingIndicator,
  Typography,
  tokens,
} from "@sunrun/experience-ui-components";
import styled from "@emotion/styled/macro";
// This is an exception as this "page" renders pages.
// eslint-disable-next-line import/no-restricted-paths
import { PricingPage } from "components/pages/PricingPage";
import { HeaderContentLayout } from "components/templates/HeaderContentLayout";
import { useAppSelector } from "store";
import { ErrorComponent } from "components/atoms/ErrorComponent";
import { amendOfferWithSync, getEditOffer } from "services/storeFront";
import { useCapContext } from "cap/providers/CapContext";
import { useNavigateHelper } from "utils/useNavigateHelper";
import { FeatureFlags } from "fixtures/features";
import { ChangeOrderDelayModal } from "components/organisms/ChangeOrderDelayModal";

const RepChangeOrderPricingPage = () => {
  const cognitoToken = useAppSelector(
    (state) => state.auth.cognitoToken?.token
  );
  const flags = useFlags([FeatureFlags.REP_REPRICING_RELEASE_V1]);
  const navigate = useNavigateHelper({ replace: true });
  const [reviewOfferId, setReviewOfferId] = useState<string>();
  // const [selectedPricePointId, setSelectedPricePointId] = useState<string>(); // TODO: In v2, save selected price point
  const [error, setError] = useState<Error | null>(null);
  const [warning, setWarning] = useState<Error | null>(null);
  const { activeProposal, prospectId } = useCapContext();
  const [lightmileProjectId, setLightmileProjectId] = useState<string>();
  const canViewChangeOrder =
    flags[FeatureFlags.REP_REPRICING_RELEASE_V1]?.enabled;
  const CHANGE_REQUEST_FORM = `https://docs.google.com/forms/d/e/1FAIpQLSdWCQVz9M_suzlN3Zohfw-BgsUENb3enUr8isvwMS0b21lrjA/viewform`;

  useEffect(() => {
    if (!prospectId || !cognitoToken || !activeProposal) {
      return;
    }

    amendOfferWithSync(
      cognitoToken,
      prospectId,
      activeProposal.proposalId,
      activeProposal.proposalId
    )
      .then((amendData) => {
        setReviewOfferId(amendData);
        getEditOffer(cognitoToken, amendData)
          .then((editData) => {
            setLightmileProjectId(editData?.lightmileProjectId);
          })
          .catch((err) => {
            setError(err);
          });
      })
      .catch((err) => {
        setError(err);
      });
  }, [cognitoToken, prospectId, activeProposal]);

  if (!canViewChangeOrder) {
    return null;
  }

  if (error) {
    return (
      <Modal
        title="There was an error loading your data."
        type="error"
        onClose={() => setError(null)}
      >
        <ErrorComponent
          context="RepChangeOrderPricingPage"
          instructions="Please refresh the page."
          error={error}
        />
      </Modal>
    );
  }
  if (warning) {
    return <ChangeOrderDelayModal setWarning={setWarning} />;
  }

  return (
    <HeaderContentLayout
      smallPadding
      description={
        <Typography>
          This section is intended for viewing pricing information. To request a
          contract change, you will need to complete the{" "}
          <StyledAnchor
            href={CHANGE_REQUEST_FORM}
            target="_blank"
            rel="noopener noreferrer"
          >
            Contract Change Request Form 2.0.
          </StyledAnchor>
        </Typography>
      }
      content={
        !reviewOfferId ? (
          <div>
            <LoadingIndicator color="black" message="Getting pricing..." />
          </div>
        ) : (
          <PricingPage
            lightmileProjectId={lightmileProjectId}
            repChangeOrder
            offerId={reviewOfferId}
            prospectId={prospectId!}
            changeOrder={true}
            offerEvSpan={false}
            submittingOffer={false}
            onPricePointIdSelected={() => {}}
            setChangeOrderError={setWarning}
            // onPricePointIdSelected={(pricePointId) => {
            //   setSelectedPricePointId(pricePointId);  // TODO: fix in v2
            // }}
            onSubmitFailure={() => {
              // TODO: v2
            }}
            onSubmitComplete={() => {
              // TODO: v2
            }}
          />
        )
      }
      title="Pricing"
      buttons={[
        {
          type: "secondary",
          text: "Back",
          onClick: () => {
            navigate(`/cap`);
          },
        },
      ]}
    />
  );
};

const StyledAnchor = styled.a`
  text-decoration: underline;
  color: ${tokens.BRAND_HEROBLUE};
  cursor: pointer;
`;

export { RepChangeOrderPricingPage };
