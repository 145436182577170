import { AmplifyGet } from "@sunrun/sales-experience-shared/amplify-api-wrapper";
import { logger } from "providers/logger";

/**
 * This function is meant to be used with SWR as a `fetcher`.
 * It accepts a URL and an Auth key and will wait for the auth key be provided
 * before invoking the URL using AWS Amplify api. The data will be cached by SWR and
 * then returned to the component. Errors are handled by SWR.
 *
 * @param url The URL to GET.
 * @param authkey Auth key retrieved from one-container
 * @see https://swr.vercel.app/docs/data-fetching
 * @see https://swr.vercel.app/docs/error-handling
 */
async function getWithAuth<R>([action, url, authKey]: [
  action: string,
  url: string | undefined,
  authKey: string | undefined
]) {
  if (!authKey || authKey === "") {
    return undefined;
  }
  if (!url) {
    return undefined;
  }
  const resp: R = await AmplifyGet(
    action,
    "OfferExpApi",
    url,
    {
      headers: {
        Authorization: `Bearer ${authKey}`,
      },
    },
    logger
  );
  return resp;
}

export { getWithAuth };
